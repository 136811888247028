import React, { Suspense, useCallback } from 'react';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import BottomSheetPanel from '@dev-togetherprice/components.bottom-sheet-panel';
import TrustLinearProgress from '@dev-togetherprice/components.trust-linear-progress';
import {
  Button, Link, Typography, useTheme
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useUser } from '@dev-togetherprice/api.user';
import useLocationState, {
  useSetLocationStateAction,
} from '@dev-togetherprice/hooks.use-location-state';
import { useNavigateToUrl } from '@dev-togetherprice/components.app-context';
import { Trans } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { differenceInMinutes } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

export type AdminInfoPanelData = {
  userId: number;
  sharingPostId: number;
  trust: number;
  lastAccess: number;
  avgResponseTime: number;
};
type InfoPanelState = {
  open: boolean;
  data?: AdminInfoPanelData;
};

const key = 'InfoPanel';
const initialInfoPanelState: InfoPanelState = {
  open: false,
  data: null,
};

export const useOpenAdminInfoPanel = (): ((
  data: AdminInfoPanelData
) => void) => {
  const setInfoPanel = useSetLocationStateAction<InfoPanelState>(
    key,
    initialInfoPanelState
  );
  return useCallback(
    (data: AdminInfoPanelData) => setInfoPanel({
      open: true,
      data,
    }),
    [setInfoPanel]
  );
};

export const useCloseAdminInfoPanel = (): (() => void) => {
  const setInfoPanel = useSetLocationStateAction<InfoPanelState>(
    key,
    initialInfoPanelState
  );
  return () => setInfoPanel((prev) => ({
    ...prev,
    open: false,
  }));
};

const useStyles = makeStyles(() => ({
  action: {
    marginTop: 80,
  },
  actionLabel: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
  },
}));

type AdminInfoPanelContentProps = {
  userId: number;
  sharingPostId: number;
  trust: number;
  lastAccess: number;
  avgResponseTime: number;
};
const AdminInfoPanelContent: React.FC<AdminInfoPanelContentProps> = ({
  userId,
  sharingPostId,
  trust,
  lastAccess,
  avgResponseTime,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigateToUrl = useNavigateToUrl();
  const { postCreated } = useUser(userId, { suspense: false }) || {};
  const lastAccessDelta = differenceInMinutes(new Date(), new Date(lastAccess));
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant='body2'>
          <Trans i18nKey='AdminInfoPanel.trust-description'>
            Definisce l’affidabilità dell’Admin. Più è alto e più avrai una
            buona esperienza di condivisione!
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TrustLinearProgress value={trust} variant='static' />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Typography variant='h6' component='h2'>
          <Trans i18nKey='AdminInfoPanel.admin-activity-title'>
            Admin Activity
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <Grid
            container
            style={{
              borderRadius: 8,
              background: theme.palette.primary.contrastText,
              padding: theme.spacing(2),
            }}
          >
            <Grid item xs>
              <Typography display='inline'>
                <Trans i18nKey='AdminInfoPanel.admin-activity-last-access-title'>
                  Last access:
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography display='inline' color='primary'>
                <Trans
                  i18nKey='AdminInfoPanel.admin-activity-last-access-value_interval'
                  tOptions={{
                    postProcess: 'interval',
                  }}
                  values={{
                    count: lastAccessDelta || 43231,
                  }}
                >
                  Few days ago
                </Trans>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            style={{
              borderRadius: 8,
              padding: theme.spacing(2),
            }}
          >
            <Grid item xs>
              <Typography display='inline'>
                <Trans i18nKey='AdminInfoPanel.admin-activity-avg-response-time-title'>
                  Avg. Response Time:
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography display='inline' color='primary'>
                <Trans
                  i18nKey='AdminInfoPanel.admin-activity-avg-response-time-value_interval'
                  tOptions={{
                    postProcess: 'interval',
                  }}
                  values={{
                    count: avgResponseTime ?? -1,
                  }}
                >
                  A Few minutes
                </Trans>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            style={{
              borderRadius: 8,
              background: theme.palette.primary.contrastText,
              padding: theme.spacing(2),
            }}
          >
            <Grid item xs>
              <Typography display='inline'>
                <Trans i18nKey='AdminInfoPanel.admin-activity-admin-of-title'>
                  Admin of:
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              {postCreated === undefined ? (
                <Skeleton variant='text' width={70} height={20} />
              ) : (
                <Typography display='inline' color='primary'>
                  <Trans
                    i18nKey='AdminInfoPanel.admin-activity-admin-of-value_interval'
                    tOptions={{
                      postProcess: 'interval',
                    }}
                    values={{ count: postCreated }}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant='body2' align='center'>
            <Link
              href={`/profile/${userId}`}
              onClick={(e) => {
                e.preventDefault();
                navigateToUrl(`/profile/${userId}`);
              }}
              underline='always'
            >
              <Trans i18nKey='AdminInfoPanel.more-info'>Additional Info</Trans>
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {sharingPostId && (
        <Container>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            size='large'
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={() => navigateToUrl(`/post/${sharingPostId}`)}
            classes={{ label: classes.actionLabel }}
            className={classes.action}
          >
            <Trans i18nKey='AdminInfoPanel.more-actions-sharing-group'>
              Sharing Group Page
            </Trans>
          </Button>
        </Container>
      )}
    </Grid>
  );
};

export type AdminInfoPanelProps = {};
const AdminInfoPanel: React.FC<AdminInfoPanelProps> = () => {
  const { value: state } = useLocationState(key, initialInfoPanelState);
  const close = useCloseAdminInfoPanel();
  const open = !!state.open;
  return (
    <BottomSheetPanel
      keepMounted
      open={open}
      onClose={close}
      onOpen={() => {}}
      title={
        ((
          <Typography variant='h6' component='h2'>
            <Trans i18nKey='AdminInfoPanel.title'>Trust Level:</Trans>
          </Typography>
        ) as unknown) as string
      }
    >
      {state.data && <AdminInfoPanelContent {...state.data} />}
    </BottomSheetPanel>
  );
};

export default AdminInfoPanel;
