import { Fetcher } from "@bit/together-price.core.api.fetch";
import { fetchNetwork, fetchUms } from "@bit/together-price.core.api.services";
import { RelationType } from "@bit/together-price.core.components.add-user-modal";
import { MoneyLike } from "@bit/together-price.core.monetary";
import useSWR, { Key, SWRConfiguration } from "swr";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";

export type SupportedMarketCountry = "IT" | "GB" | "ES" | "US";
export type PostSecretStatus = "VALID" | "WRONG" | "UNKNOWN" | "MISSING";
export type PostAdmin = {
  userId: number;
  adminEmail: string;
  adminFullname: string;
  imagePath?: string;
  verified?: boolean;
  emailVerified?: boolean;
  cellphoneVerified?: boolean;
  ratings?: number;
  trust?: number;
  lastSession?: number;
  adminAvgTime?: number;
};
export type PostJoiner = {
  userId: number;
  name: string;
  surname: string;
  imagePath?: string;
};
export type PostFrequency = "monthly" | "annual";
export type Post = {
  sharingPostId: number;
  serviceId: number;
  postTitle: string;
  imagePath: string;
  feePrice: MoneyLike;
  monthlyPrice: MoneyLike;
  frequency: PostFrequency;
  freeAvailability: number;
  totalAvailability: number;
  hasFreeSlots: boolean;
  admin: PostAdmin;
  tags: string[];
  joiners: PostJoiner[];
  secretStatus?: PostSecretStatus;
  category: SearchCategory;
  cratedAt: string;
  marketCountry: SupportedMarketCountry;
};
export enum Status {
  OWNER = "owner",
  JOINER = "joiner",
  INSIDE = "inside",
  PENDING_PAYMENT = "pending_payment",
}
export type Admin = {
  id: number;
  image: string;
  name: string;
  surname: string;
  trust?: number;
};
export type Me = {
  status: Status;
  lastPayment: string;
  nextRenewal: string;
  youWillLeave: string;
};
export type Details = {
  createdAt: number;
  freeSlots: number;
  frequency: PostFrequency;
  id: number;
  image: string;
  isPublic: Boolean;
  closedAt: string;
  price: MoneyLike;
  fee: MoneyLike;
  serviceGroupId: number;
  serviceId: number;
  serviceTag: string;
  status: string;
  subscription: string;
  title: string;
  totalSlots: number;
  secretStatus: PostSecretStatus;
};
export type GroupPost = {
  admin: Admin;
  me: Me;
  details: Details;
};
export const usePost = (
  id: string | number,
  config: SWRConfiguration<Post[], any> = {}
) =>
  useSWR<any>(`/sharingPost/${id}`, {
    fetcher: fetchNetwork as Fetcher<Post[]>,
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshWhenHidden: true,
    refreshInterval: 0,
    suspense: true,
    dedupingInterval: 3000,
    ...config,
  });

export const usePosts = (
  query: Key,
  config: SWRConfiguration<Post[], any> = {}
) => {
  return useSWR<Post[]>(query, {
    fetcher: fetchNetwork as Fetcher<Post[]>,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
    suspense: true,
    dedupingInterval: 8000,
    ...config,
  });
};

export const generateGetKey = (query: string, size: number) => (pageIndex) =>
  [query, `page=${pageIndex}&size=${size}`].join(
    query.includes("?") ? "&" : "?"
  );

export const usePostsInfinite = (
  query: string,
  size = 8,
  options: SWRInfiniteConfiguration = {}
) =>
  useSWRInfinite<Post[]>(query && generateGetKey(query, size), {
    fetcher: fetchNetwork as Fetcher<any>,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
    suspense: true,
    dedupingInterval: 8000,
    revalidateAll: false,
    ...options,
  });

const categories = [
  "teamwork",
  "software",
  "streaming",
  "music",
  "sport",
  "productivity",
  "gaming",
  "reading",
  "security",
  "privacy",
  "storage",
  "education",
  "documentary",
  "photography",
  "bundle",
] as const;
export type SearchCategory = typeof categories[number];
export const allSearchFilters = [
  "identity_verified",
  "validated_credentials",
  "online_now",
  "today_last_access",
  "six_month_member",
  "one_slot_available",
] as const;
export type SearchFilter = typeof allSearchFilters[number];
export type SortDirection = "asc" | "desc";
type FlatKeyOf<T> = T extends object ? keyof T | FlatKeyOf<T[keyof T]> : never;
export type SortProperty = keyof Post;
export type SearchQueryParams = {
  q?: string;
  serviceId?: number;
  marketCountry?: SupportedMarketCountry | string;
  tags?: string[];
  filters?: (SearchFilter | string)[];
  props?: (SortProperty | string)[];
  direction?: SortDirection | string;
  categories?: (SearchCategory | string)[];
  size?: number;
  onlyCompleted?: boolean;
};

export type SearchApiUrl =
  | "/carousel/byServiceLikes"
  | "/carousel/byFriends"
  | "/carousel/byCategories"
  | "/carousel/search"
  | "/carousel/publicByCategories"
  | "/carousel/publicSearch";

export const searchQuery = (
  searchApiUrl: SearchApiUrl | string,
  params: SearchQueryParams = {}
): string => {
  const [path, oldparams] = String(searchApiUrl).split("?");
  const urlSearchParams = new URLSearchParams(oldparams);
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      if (typeof value === "object") {
        if (value instanceof Array && value.length !== 0) {
          value.forEach((val) => urlSearchParams.append(key, val));
        }
      } else urlSearchParams.set(key, value);
    }
  });
  return [path, urlSearchParams.toString()].filter(Boolean).join("?");
};

export type Person = {
  userId: number;
  imagePath: string;
  fullname: string;
  name: string;
  surname: string;
  trust: number;
  isRegistered: boolean;
  activeGroups: number;
  groupsInfo: Record<string, string>;
  lastLogin: number;
  lastSession: number;
  avgResponseTime: number;
  relation?: RelationType;
};

export type PeopleSearchPreview = {};

export type SearchPreview = {
  posts: Post[];
  contacts: Person[];
  phoneBookUsers: Person[];
  users: Person[];
};

export const useSearchPreview = (query: string) =>
  useSWR<SearchPreview>(
    `/carousel/networkMultiSearch?q=${query}`,
    fetchNetwork,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      suspense: false,
      dedupingInterval: 8000,
      revalidateOnMount: true,
    }
  );

export const usePeopleInPlatform = (query: string, size = 16) =>
  useSWRInfinite<Person[]>(
    (page) =>
      query &&
      `/peopleNetwork/searchInPlatformUsers?q=${query}&page=${page}&size=${size}`,
    fetchUms,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      suspense: false,
      dedupingInterval: 8000,
      revalidateOnMount: true,
    }
  );
