import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import IconButton from '@bit/together-price.core.components.icon-button';
import VerticalCentered from '@bit/together-price.core.components.vertical-centered';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import CategoryIcon from '@/components/CategoryIcon';
import { Service } from '@/api/service';
import ServiceName from '@/components/ServiceName';
import ServiceCategoryDescription from '@/components/ServiceCategoryDescription';

export interface SearchServiceCardProps extends Service {
  className?: string;
}
export const SearchServiceCard: React.FC<SearchServiceCardProps> = ({
  name,
  tag,
  className,
  categories: [category],
}) => {
  const theme = useTheme();
  const navigateToUrl = useNavigateToUrl();

  return (
    <Box p={10} width='100%' className={className}>
      <Grid container spacing={5}>
        <Grid item>
          <VerticalCentered>
            <Container
              style={{
                width: '42px',
                height: '42px',
                background: theme.palette.primary.light,
                padding: theme.spacing(0.5),
                borderRadius: theme.spacing(2.5),
              }}
            >
              <CategoryIcon
                style={{
                  fontSize: '40px',
                  transform: 'translate(-20%, -20%)',
                }}
                variant={category}
                color='primary'
                fontSize='large'
              />
            </Container>
          </VerticalCentered>
        </Grid>
        <Grid item xs>
          <Typography variant='subtitle1' style={{ fontSize: '16px' }}>
            <ServiceName value={name} />
          </Typography>
          <Typography variant='subtitle2'>
            <ServiceCategoryDescription value={category} />
          </Typography>
        </Grid>
        <Grid item>
          <VerticalCentered>
            <IconButton
              variant='outlined'
              color='primary'
              size='medium'
              style={{
                width: '45px',
                height: '45px',
                background: theme.palette.common.white,
              }}
              onClick={() => navigateToUrl(`/rules/${tag}`)}
            >
              ?
            </IconButton>
          </VerticalCentered>
        </Grid>
      </Grid>
    </Box>
  );
};
