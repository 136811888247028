/* eslint-disable implicit-arrow-linebreak */
import {
  fetchNetwork,
  fetchNotification,
  fetchUms,
} from '@dev-togetherprice/api.services';

type CellphoneVerification = {
  cellphone: string;
  whatsappPermission: boolean;
};

type PublishProps = {
  name: string;
  description: string;
  categoryId: number;
  totalAvailability: number;
  price: any;
  isPublic: boolean;
  frequency: string;
  relation: string;
  subscription: string;
  serviceId: number;
  coverLink?: string;
  coverData?: any;
  promoId?: number
};
export const publish = ({
  name,
  description,
  categoryId,
  totalAvailability,
  price,
  isPublic,
  frequency,
  relation,
  subscription,
  serviceId,
  coverLink,
  coverData,
  promoId
}: PublishProps): Promise<void> => {
  const payload = new window.FormData();
  payload.append(
    'wrapper',
    new Blob(
      [
        JSON.stringify({
          title: name,
          description,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );
  payload.append('categoryId', String(categoryId));
  payload.append('totalAvailability', String(totalAvailability));
  payload.append('price', price);
  payload.append('isPublic', String(isPublic));
  payload.append('frequency', frequency);
  payload.append('relation', String(relation).toUpperCase());
  payload.append('subscription', subscription);
  payload.append('serviceId', String(serviceId));
  promoId && payload.append('promoId', String(promoId));
  if (coverLink) {
    payload.append('coverLink', coverLink);
  } else if (coverData) {
    payload.append('coverData', coverData);
  }
  return fetchNetwork(
    '/sharingPost/create',
    {
      method: 'POST',
      body: payload,
    },
    { noDefaultHeader: true }
  );
};

type UpdateProps = {
  id: string;
  name: string;
  description: string;
  file?: any;
  link?: string;
};
export const update = ({
  id,
  name,
  description,
  file,
  link,
}: UpdateProps): Promise<void> => {
  const payload = new window.FormData();
  payload.append(
    'wrapper',
    new Blob(
      [
        JSON.stringify({
          title: name,
          description,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );
  payload.append('sharingPostId', id);
  if (file) {
    payload.append('file', file.file);
  }
  if (link) {
    payload.append('coverLink', link);
  }
  payload.append('changeCover', true as any);
  return fetchNetwork(
    '/sharingPost/update',
    {
      method: 'POST',
      body: payload,
    },
    { noDefaultHeader: true }
  );
};

type MessageProps = {
  sharingPostId: number;
  message: string;
};
export const sendMessage = ({
  sharingPostId,
  message,
}: MessageProps): Promise<void> =>
  fetchNotification('/chatInfo/firstMessage', {
    method: 'POST',
    body: JSON.stringify({
      sharingPostId,
      message,
    }),
  });

export const resendVerificationEmail = (): Promise<void> =>
  fetchUms('/resendVerificationEmail', {
    method: 'POST'
  });

export const sendCellphoneVerificationCode = ({
  cellphone,
  whatsappPermission,
}: CellphoneVerification): Promise<void> =>
  fetchUms('/sendCellphoneVerificationCode', {
    method: 'POST',
    body: JSON.stringify({
      cellphone,
      whatsappPermission,
    }),
  });

type VerificationCode = {
  cellphone: string;
  code: string;
};
export const checkCellphoneVerificationCode = ({
  cellphone,
  code,
}: VerificationCode): Promise<void> =>
  fetchUms('/checkCellphoneVerificationCode', {
    method: 'POST',
    body: JSON.stringify({
      cellphone,
      code,
    }),
  });

interface Secret {
  [key: string]: string;
}
type CreateSecretType = {
  secret: Secret;
  sharingPostId: number;
  secretType?: string;
};
export const createSecrets = ({
  secret,
  sharingPostId,
  secretType,
}: CreateSecretType): Promise<void> =>
  fetchUms('/sharing/secrets/setSecrets', {
    method: 'POST',
    body: JSON.stringify({
      secret: JSON.stringify(secret),
      secretType,
      sharingPostId,
    }),
  });
