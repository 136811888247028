import {
  MutableRefObject, useEffect, useRef, useState
} from 'react';

const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });
};

const INTERSECTING_EVENT_NAME = 'intersecting';

const observer: IntersectionObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      entry.target.dispatchEvent(new CustomEvent(INTERSECTING_EVENT_NAME));
    }
  });
});

const cache = new Map<string, boolean>();

// eslint-disable-next-line max-len
export const useLazyImage = <T extends HTMLElement = HTMLElement>(src: string): [MutableRefObject<T | undefined>, boolean] => {
  const ref = useRef<T>();
  const [loaded, setLoaded] = useState(cache.get(src) ?? false);
  useEffect(() => {
    if (!src) return undefined;
    if (loaded) return undefined;
    const element = ref.current;
    if (!element) return undefined;
    const listener = () => {
      preloadImage(src)
        .then(() => {
          setLoaded(true);
          cache.set(src, true);
        })
        .catch(() => {
          setLoaded(false);
          cache.set(src, false);
        });
    };
    element.addEventListener(INTERSECTING_EVENT_NAME, listener);
    observer.observe(element);
    return () => {
      observer.unobserve(element);
      element.removeEventListener(INTERSECTING_EVENT_NAME, listener);
    };
  }, [src, loaded]);
  return [ref, loaded];
};

export default useLazyImage;
