/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
import { resendVerificationEmail, sendCellphoneVerificationCode } from '@/api/create';
import {
  Button,
  lighten,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import prefixes from '@dev-togetherprice/constants.prefixes';
import DashedDescription from '@dev-togetherprice/components.dashed-description';
import Select from '@dev-togetherprice/components.select';
import { updateFunnel } from '@dev-togetherprice/api.user';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  errorState,
  phonePrefixState,
  phoneState,
  stepState,
  whatsappState,
} from '@/recoil/atoms/create';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginBottom: theme.spacing(7.5),
  },
  outlined: {
    fontFamily: 'Poppins',
    padding: theme.spacing(4.225, 2.5),
    borderRadius: theme.spacing(2),
    border: `1px solid ${lighten(theme.palette.primary.main, 0.5)}`,
  },
  phoneWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  button: {
    height: 55,
    boxShadow: 'none',
    borderRadius: theme.spacing(2),
    textTransform: 'none',
    transition: '.4s',
  },
  box: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4)
  },
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    opacity: 0.5,
  },
  confirm: {
    width: '100%',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  checkbox: {
    color: theme.palette.primary.main,
    paddingTop: 0,
  },
}));
export const SpinnerAdornment = withStyles({
  root: {
    color: 'white',
  },
})((props) => <CircularProgress {...props} color='inherit' size={20} />);

const ChoosePhone = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [phone, setPhone] = useRecoilState(phoneState);
  const [phonePrefix, setPhonePrefix] = useRecoilState(phonePrefixState);
  const [whatsapp, setWhatsapp] = useRecoilState(whatsappState);

  const setError = useSetRecoilState(errorState);
  const setStep = useSetRecoilState(stepState);

  const opt = prefixes.map((p) => ({
    name: p.label,
    value: p.value,
  }));

  useEffect(() => {
    updateFunnel({ column: 'phone_verification', isCamelCase: true });
  }, []);

  const validatePhoneNumber = () => {
    setIsLoading(true);
    sendCellphoneVerificationCode({
      cellphone: ['+', phonePrefix, phone.replaceAll(' ', '')].join(''),
      whatsappPermission: whatsapp,
    })
      .then(() => {
        setStep((s) => s + 1);
      })
      .catch(({ data: { message } }) => {
        if (message === "USER_EMAIL_NOT_VERIFIED_YET") {
          setIsEmailVerified(false)
          setError(t('Create.email.verificationError'));
          return;
        }
        setError(message || t('Create.phone.invalid'));
      })
      .finally(() => setIsLoading(false));
  };

  const resendEmail = () => {
    setIsResendLoading(true)
    resendVerificationEmail().catch(() => {
      setError(t('Create.email.resendError'))
    }).finally(() => setIsResendLoading(false));
  }

  if (!isEmailVerified) {
    return (<Grid className={classes.root} container item xs={12} direction='column'>
      <Typography
        variant='h1'
        color='primary'
        align='center'
        className={classes.title}
      >
        <Trans i18nKey='Create.email.title' />
      </Typography>
      <Typography
        variant='body1'
        color='textSecondary'
        className={classes.title}
      >
        <Trans i18nKey='Create.email.description' />
      </Typography>
      <Box className={classes.box}>
        <Button
          variant='contained'
          color='primary'
          classes={{ root: classes.button, disabled: classes.disabled }}
          disabled={isLoading}
          onClick={validatePhoneNumber}
        >
          {isLoading ? (
            <SpinnerAdornment />
          ) : (
            <Trans i18nKey='Create.email.tryAgainButton' />
          )}
        </Button>
        <Button
          variant='text'
          color='primary'
          className={classes.button}
          disabled={isResendLoading}
          onClick={resendEmail}
        >
          {isResendLoading ? (
            <SpinnerAdornment />
          ) : (
            <Trans i18nKey='Create.email.resendButton' />
          )}
        </Button></Box>
    </Grid>)
  }

  return (
    <Grid className={classes.root} container item xs={12} direction='column'>
      <Typography
        variant='h1'
        color='primary'
        align='center'
        className={classes.title}
      >
        <Trans i18nKey='Create.phone.title' />
      </Typography>
      <FormControl>
        <Select
          defaultValue='+39'
          onChange={(value) => {
            setPhonePrefix(+value);
          }}
          options={opt}
        />
      </FormControl>
      <FormControl className={classes.phoneWrapper}>
        <MaskedInput
          placeholder={t('Create.phone.placeholder')}
          onChange={({ target: { value } }) => {
            setPhone(value);
          }}
          value={phone}
          className={classes.outlined}
          mask={createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: false,
            integerLimit: 12,
            allowNegative: false,
            allowLeadingZeroes: false,
            includeThousandsSeparator: false,
          })}
          inputMode='numeric'
          guide={false}
        />
      </FormControl>
      <Button
        variant='contained'
        color='primary'
        classes={{ root: classes.button, disabled: classes.disabled }}
        disabled={phone.replaceAll(' ', '').length < 8}
        onClick={validatePhoneNumber}
      >
        {isLoading ? (
          <SpinnerAdornment />
        ) : (
          <Trans i18nKey='Create.phone.send' />
        )}
      </Button>
      <FormControl className={classes.confirm}>
        <FormControlLabel
          checked={!whatsapp}
          onChange={() => setWhatsapp((w) => !w)}
          label={
            <Typography variant='body2' color='textSecondary'>
              <Trans i18nKey='Create.phone.whatsapp' />
            </Typography>
          }
          control={<Checkbox className={classes.checkbox} color='primary' />}
        />
      </FormControl>
      <DashedDescription
        title={<Trans i18nKey='Create.phone.description.title' />}
        body={<Trans i18nKey='Create.phone.description.body' />}
      />
    </Grid>
  );
};

export default memo(ChoosePhone);
