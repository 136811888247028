import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  Radio,
  Typography,
} from '@material-ui/core';
import Modal from '@dev-togetherprice/components.modal';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import FiltersIcon from '@dev-togetherprice/icons.filters';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.light,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    borderRadius: theme.spacing(3),
    height: '40px',
    width: 'max-content',
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonLabel: {
    textTransform: 'none',
    justifyContent: 'space-between',
  },
  arrow: {
    fontSize: '30px !important',
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },
  moreChip: {
    display: 'inline',
    padding: theme.spacing(1),
    fontSize: 'inherit',
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
  },
  drawer: {
  },
  formControl: {
    justifyContent: 'space-between',
    width: 'calc( 100% - 30px )',
  },
  formControlChecked: {
    color: theme.palette.primary.main,
  },
}));

export type ControlType = 'filter' | 'sort';

export type ControlChangeHandler = (
  value: string,
  checked: boolean,
  type: ControlType
) => void;

export type SearchControlsProps = {
  value?: string[];
  onChange?: ControlChangeHandler;
  allSorts?: Array<String>;
  allFilters?: Array<String>;
};
const SearchControls: React.FC<SearchControlsProps> = ({
  value = [],
  allSorts,
  allFilters,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const getControlClassName = (key: string) => clsx(classes.formControl, {
    [classes.formControlChecked]: value.includes(key),
  });

  const getButtonText = () => {
    if (value.length === 1) {
      return <Trans i18nKey={`SearchControls.${value[0].replaceAll('.', '_')}`} />;
    }
    if (value.length > 1) {
      return (
        <>
          <Trans i18nKey={`SearchControls.${value[0].replaceAll('.', '_')}`} />
          ,
          {' '}
          <Avatar component='span' color='primary' className={classes.moreChip}>
            {`+${value.length - 1}`}
          </Avatar>
        </>
      );
    }
    return (
      <Trans i18nKey='SearchControls.placeholder'>
        Nessun filtro selezionato
      </Trans>
    );
  };
  return (
    <>
      <Button
        name='search-controls'
        fullWidth
        onClick={() => setOpen(true)}
        classes={{
          root: classes.button,
          label: classes.buttonLabel,
        }}
        color='primary'
        size='large'
        startIcon={<FiltersIcon />}
        endIcon={
          open ? (
            <ExpandLessIcon className={classes.arrow} />
          ) : (
            <ExpandMoreIcon className={classes.arrow} />
          )
        }
      >
        <Typography className={classes.buttonText} align='left'>
          {getButtonText()}
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <Container>
          <Grid
            container
            justify='space-between'
            alignContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs />
            <Grid item>
              <IconButton color='primary' onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {allSorts && allSorts.length > 0 && (
              <Grid item xs={12}>
                <Container>
                  <Typography
                    color='primary'
                    variant='h6'
                    id='search-controls-sorts-title'
                  >
                    <Trans i18nKey='SearchControls.sort-title'>
                      Ordina per:
                    </Trans>
                  </Typography>
                </Container>
                <FormGroup aria-label='search-controls-sorts-title'>
                  {allSorts.map((controlKey: string) => (
                    <FormControlLabel
                      key={controlKey}
                      name={controlKey}
                      control={<Radio color='primary' />}
                      label={<Trans i18nKey={`SearchControls.${controlKey.replaceAll('.', '_')}`} />}
                      labelPlacement='start'
                      className={getControlClassName(controlKey)}
                      onChange={(_, changed) => onChange?.(controlKey, changed, 'sort')}
                      checked={value.includes(controlKey)}
                    />
                  ))}
                </FormGroup>
              </Grid>
            )}
            <Grid item xs={12} />
            {allFilters && allFilters.length > 0 && (
              <Grid item xs={12}>
                <Container>
                  <Typography
                    color='primary'
                    variant='h6'
                    id='search-controls-filters-title'
                  >
                    <Trans i18nKey='SearchControls.filter-title'>
                      Filtra per:
                    </Trans>
                  </Typography>
                </Container>
                <FormGroup aria-label='search-controls-filters-title'>
                  {allFilters.map((controlKey: string) => (
                    <FormControlLabel
                      key={controlKey}
                      name={controlKey}
                      control={<Checkbox color='primary' />}
                      label={<Trans i18nKey={`SearchControls.${controlKey.replaceAll('.', '_')}`} />}
                      labelPlacement='start'
                      className={getControlClassName(controlKey)}
                      onChange={(_, changed) => onChange?.(controlKey, changed, 'filter')}
                      checked={value.includes(controlKey)}
                    />
                  ))}
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </Container>
      </Modal>
    </>
  );
};

export default SearchControls;
