import React, { ReactNode, useEffect, useState } from 'react';
import Notification from '@dev-togetherprice/components.notification';
import useLocationState, {
  useSetLocationStateAction,
} from '@dev-togetherprice/hooks.use-location-state';
import { Snackbar } from '@material-ui/core';

const key = 'Notification';
export type NotificationData = {
  severity: 'warning';
  message: ReactNode;
};
export const useShowNotification = () =>
  useSetLocationStateAction<NotificationData>(key);

const NotificationsContainer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const {
    value: notificationProps,
    setLocationState: setNotificationProps,
  } = useLocationState<NotificationData>(key);
  const closeNotification = () => setOpen(false);

  useEffect(() => {
    if (notificationProps) {
      setOpen(true);
    }
  }, [notificationProps]);

  useEffect(() => {
    if (!open) {
      setNotificationProps(null);
    }
  }, [open, setNotificationProps]);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={8000}
        onClose={closeNotification}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        style={{ top: 0 }}
      >
        {notificationProps && (
          <Notification
            severity={notificationProps?.severity}
            onClick={closeNotification}
          >
            {notificationProps?.message}
          </Notification>
        )}
      </Snackbar>
    </div>
  );
};

export default NotificationsContainer;
