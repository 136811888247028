import useSWR, { mutate } from 'swr';
import { fetchUms } from '@bit/together-price.core.api.services';
import { Fetcher } from '@bit/together-price.core.api.fetch';

type UserOnBoardingKeys =
  | 'serviceLike'
  | 'wowEffect'
  | 'firstChoice'
  | 'network'
  | 'postJoinInfo'
  | 'sharingServiceInfo'
  | 'payGroup'
  | 'sharingServiceCustomInfo'
  | 'adminCreation'
  | 'creationSuccess'
  | 'requestSentInfo'
  | 'postEditInfo'
  | 'tpasswordPostRow'
  | 'tpasswordManagerForm'
  | 'autoPromo'
  | 'landedDisneyPlus'
  | 'completed'
  | 'crowdfundingModal';

type UserOnBoarding = Partial<Record<UserOnBoardingKeys, number>>;
type UpdateUserOnBoarding = {
  column: UserOnBoardingKeys;
};

export const useOnBoarding = (): UserOnBoarding =>
  useSWR('/getUserOnboarding', {
    fetcher: fetchUms as Fetcher<UserOnBoarding>,
    suspense: true,
    revalidateOnFocus: false,
    dedupingInterval: 30000,
  }).data;

export const updateOnboarding = (data: UpdateUserOnBoarding): Promise<void> =>
  fetchUms(
    '/updateOnBoarding',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
    { noDefaultHeader: true }
  ).then(() => mutate('/getUserOnboarding'));
