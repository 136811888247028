const publicFaqsUrl = {
  IT: 'https://www.togetherprice.com/it/tp-faq/',
  US: 'https://www.togetherprice.com/us/faq-tp/',
  ES: 'https://www.togetherprice.com/es/faq-tp/',
  GB: 'https://www.togetherprice.com/uk/faq-tp/',
};

export const getFaqUrl = (market: string): string => publicFaqsUrl[market];

export const goToFaq = (market: string): void => {
  window.location.href = getFaqUrl(market);
};
