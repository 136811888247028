import { Service } from '@/api/service';
import { queryState, tagsState } from '@/recoil/atoms/search';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useSearchServices from './useSearchServices';

export type UseCurrentServiceOptions = {
  marketCountry: string;
};

export type UseCurrentServiceResponse = Service | null;

const useCurrentService = (
  marketCountry: string
): UseCurrentServiceResponse => {
  const query = useRecoilValue(queryState);
  const tags = useRecoilValue(tagsState);

  const services = useSearchServices(marketCountry);

  const currentService = useMemo(() => {
    if (tags.length === 1) {
      return services?.find(({ tag }) => tags.includes(tag));
    }
    return services?.find(({ name }) => {
      return query?.toLowerCase().startsWith(name.toLowerCase());
    });
  }, [tags, services, query]);

  return currentService;
};

export default useCurrentService;
