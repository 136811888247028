import {
  fade, lighten, makeStyles, Theme
} from '@material-ui/core';

const useSharingPreviewPanelStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    // maxHeight: '500px',
    borderRadius: '40px 40px 0px 0px',
    background: theme.palette.common.white,
    '& $header': {
      padding: theme.spacing(6, 4, 0),
    },
    '& $content': {
      padding: theme.spacing(6, 4),
      borderRadius: '40px 40px 0px 0px',
    },
    '& $actions': {
      padding: theme.spacing(0),
      background: theme.palette.primary.light,
      borderRadius: '30px 30px 0px 0px',
    },
  },
  stepInfo: {
    background: theme.palette.primary.light,
  },
  stepHow: {
    background: theme.palette.primary.light,
  },
  header: {},
  content: {},
  actions: {},
  categoryContainer: {
    width: '42px',
    height: '42px',
    background: theme.palette.primary.light,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(2.5),
  },
  categoryIcon: {
    fontSize: '40px',
  },
  title: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
  },
  titleStrong: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
  },
  priceContainer: {
    padding: theme.spacing(0, 5),
  },
  priceContainerColored: {
    background: lighten(theme.palette.primary.light, 0.7),
    padding: theme.spacing(4, 5),
    borderRadius: 30,
  },
  frequency: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  priceRow: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
  },
  price: {
    fontSize: 21,
    lineHeight: '31px',
    fontWeight: 600,
    margin: 0,
  },
  divider: {
    backgroundColor: fade(theme.palette.primary.main, 0.35),
    height: 0.5,
  },
  priceInfoIcon: {
    fontSize: '25px !important',
  },
  step: {},
  stepIcon: {
    height: 31,
    width: 31,
    display: 'inline-block',
    border: `3px solid ${fade(theme.palette.primary.main, 0.3)}`,
    borderRadius: '50%',
    color: fade(theme.palette.primary.main, 0.3),
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    textAlign: 'center',
    paddingTop: '2px',
  },
  stepIconActive: {
    border: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  stepLabel: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 300,
  },
  stepConnector: {
    padding: 0,
    paddingBottom: 2,
    paddingTop: 1,
    marginLeft: '14px',
  },
  stepLine: {
    background: fade(theme.palette.primary.main, 0.3),
    width: '3px',
  },
  info: {
    background: lighten(theme.palette.secondary.light, 0.95),
    borderRadius: 8,
    padding: theme.spacing(3),
    '& img': {
      width: 24,
    },
  },
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey[700],
  },
  button: {
    borderRadius: 8,
    height: 56,
  },
  success: {
    color: '#4CAF50',
  },
  error: {
    color: '#828282',
  },
  infoRow: {
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    '&:nth-child(odd)': {
      background: lighten(theme.palette.primary.main, 0.92),
    },
  },
  adminInfoAvatar: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}), { name: 'SharingPreviewPanel' });

export default useSharingPreviewPanelStyles;
