import useSWR from 'swr';
import { MoneyLike } from '@bit/together-price.core.monetary';
import { fetchNetwork } from '@bit/together-price.core.api.services';
import { Fetcher } from '@bit/together-price.core.api.fetch';

export const Currency = {
  GBP: '£',
  EUR: '€',
  USD: '$',
  MXN: '$',
  DKK: 'kr.',
  PLN: 'zl',
  CZK: 'Kč',
  HUF: 'Ft',
  SEK: 'kr',
};

const categories = [
  'teamwork',
  'software',
  'streaming',
  'music',
  'sport',
  'productivity',
  'gaming',
  'reading',
  'security',
  'privacy',
  'storage',
  'education',
  'documentary',
  'photography',
  'bundle',
  'group',
] as const;
export type ServiceCategory = typeof categories[number];

export type SecretType = 'ADDRESS' | 'USERNAME_AND_PASSWORD';

export type Service = {
  id: number;
  name: string;
  tag: string;
  categories: ServiceCategory[];
  secretsAllowed: Array<SecretType>;
  showServiceCard: boolean;
  linkToService?: string;
};
export type SharingService = {
  id: number;
  name: string;
  tag: string;
  image: string;
};

export type Plan = {
  totalSlots: number;
  description: string;
  coverImage: string;
  frequency: string;
  categoryId: number;
  categoryName?: ServiceCategory;
  relation: string;
  price: MoneyLike;
  priceLimit: number;
  showedPrice: MoneyLike;
  currencyCode: string;
  name: string;
  tag: string;
  duration: string;
  freeTitle?: boolean;
  freeDescription: boolean;
  id: number;
  subscribable: boolean;
  canCreate: boolean;
  visibilityUpdatable?: boolean;
};

export const useSharingServices = (api = '/carousel/getServicesByCategories') =>
  useSWR(api, fetchNetwork as Fetcher<any>, {
    suspense: true,
    revalidateOnFocus: false,
    dedupingInterval: 30000,
  }).data;

export const useServices = (api = '/carousel/getServices'): Service[] =>
  useSWR(api, fetchNetwork as Fetcher<Service[]>, {
    suspense: true,
    revalidateOnFocus: false,
    dedupingInterval: 30000,
  }).data;

export const usePlan = (tag = ''): Plan[] =>
  useSWR(
    `/sharingServices/plans?tag=${tag}&onlyActivable=true`,
    fetchNetwork as Fetcher<Plan[]>,
    {
      suspense: true,
      revalidateOnFocus: false,
      dedupingInterval: 30000,
    }
  ).data;

export type ServicePlanInfo = {
  description: string;
  totalAvailability: number;
  price: string;
  link: string;
};

type ServicePlanInfoParams = {
  serviceId: number;
  language: string;
};
export const useServicePlanInfo = (
  params?: ServicePlanInfoParams,
  api = '/sharingServices/planInfo'
) =>
  useSWR(
    params &&
      `${api}?${new URLSearchParams({
        serviceId: String(params.serviceId),
        language: params.language,
      }).toString()}`,
    fetchNetwork as Fetcher<ServicePlanInfo>,
    {
      suspense: false,
      revalidateOnFocus: false,
      dedupingInterval: 30000,
    }
  ).data;
