import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { SharingCardSkeleton } from '@/components/SharingCard';
import { CompactSharingCardSkeleton } from '@/components/CompactSharingCard';

const SearchCardSkeleton = React.memo(() => {
  const theme = useTheme();
  const isDesktopVariant = useMediaQuery(theme.breakpoints.up('md'));
  return isDesktopVariant ? (
    <SharingCardSkeleton />
  ) : (
    <CompactSharingCardSkeleton />
  );
});

export default SearchCardSkeleton;
