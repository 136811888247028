import { fetchNetwork } from "@dev-togetherprice/api.services";
import { useNavigateToUrl } from "@dev-togetherprice/components.app-context";
import { useEffect } from "react";
import useSWR from "swr";
import { Post } from "./search";

const fetchAvailablePosts = async (selectedServiceId, pageNumber) => {
  const url = `/carousel/search/withSecret?q=&serviceGroupIds=${[
    selectedServiceId,
  ]}&page=${pageNumber}&size=${20}&properties=${[
    "secretStatus",
  ]}&direction=DESC`;
  return fetchNetwork(url);
};

export const useAvailablePostSearch = ({ selectedServiceId, pageNumber }) => {
  const navigateToUrl = useNavigateToUrl();
  const lastSlashIndex = location.pathname.lastIndexOf("/");
  const serviceName = location.pathname.substring(lastSlashIndex + 1);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedServiceId > 0) {
        const result = await fetchAvailablePosts(selectedServiceId, pageNumber);

        const firstPost = result[0];

        //This will trigger once you land on the page. It will happen ONE time and it checks if there is at least ONE post of any kind related to the service the user picked

        if (firstPost) {
          if (firstPost?.secretStatus === "VALID") {
            return navigateToUrl(
              `/green_key_checkout/${serviceName}/post/${firstPost.sharingPostId}`
            );
          }
          if (
            ["UNKNOWN", "MISSING", "WRONG"].includes(firstPost?.secretStatus)
          ) {
            return navigateToUrl(`/gray_key_groups/${serviceName}`);
          }
        }
        return navigateToUrl(`/unavailable_group/${serviceName}`);
      }
    };
    fetchData();
  }, [selectedServiceId]);
};

export const useSharingPost = (id: string) =>
  useSWR<Post>(`/carousel/sharingPost/${id}`, {
    fetcher: fetchNetwork,
    suspense: true,
  });
