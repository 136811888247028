import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import CinemaIcon from '@bit/together-price.core.icons.cinema';
import ClapperBoardIcon from '@bit/together-price.core.icons.clapper-board';
import ConsoleIcon from '@bit/together-price.core.icons.console';
import NoteIcon from '@bit/together-price.core.icons.note';
import ComputerIcon from '@bit/together-price.core.icons.computer';
import BrainIcon from '@bit/together-price.core.icons.brain';
import BookIcon from '@bit/together-price.core.icons.book';
import BundleIcon from '@bit/together-price.core.icons.bundle';
import IncognitoIcon from '@bit/together-price.core.icons.incognito';
import SportIcon from '@bit/together-price.core.icons.sport';
import { ServiceCategory } from '@/api/service';
import RatingUser from '@/icons/RatingUser';

const getIcon = (category: ServiceCategory | 'custom'): React.ComponentType => {
  switch (category) {
    default:
    case 'custom':
      return CinemaIcon;
    case 'group':
      return (props) => (
        <RatingUser style={{ width: 35, height: 35 }} {...props} />
      );
    case 'streaming':
      return ClapperBoardIcon;
    case 'music':
      return NoteIcon;
    case 'sport':
      return SportIcon;
    case 'productivity':
      return ComputerIcon;
    case 'gaming':
      return ConsoleIcon;
    case 'reading':
      return BookIcon;
    case 'security':
      return ComputerIcon;
    case 'privacy':
      return IncognitoIcon;
    case 'storage':
      return ComputerIcon;
    case 'education':
      return BrainIcon;
    case 'documentary':
      return BrainIcon;
    case 'photography':
      return BrainIcon;
    case 'bundle':
      return BundleIcon;
  }
};

export type CategoryIconProps = SvgIconProps & {
  variant?: ServiceCategory;
};
const CategoryIcon: React.FC<CategoryIconProps> = ({ variant, ...props }) => {
  const Icon = getIcon(variant);
  return <Icon {...props} />;
};

export default CategoryIcon;
