import { useEffect, useState } from 'react';

const supportedLanguages = ['it', 'en', 'es', 'ca'] as const;
const supportedCountries = ['IT', 'ES', 'GB', 'US'];

type SupportedLanguages = typeof supportedLanguages[number];
type SupportedCountry = typeof supportedCountries[number];

const getCountryByLanguage = (lng: SupportedLanguages | string) => {
  switch (lng) {
    case 'it':
      return 'IT';
    case 'en':
      return 'GB';
    case 'es':
      return 'ES';
    case 'ca':
      return 'ES';
    default:
      return null;
  }
};

export const getFirstSupportedCountry = () => {
  const languages = navigator.languages || [navigator.language];
  const [supportedCountry] = languages
    .map((language) => language.split('-'))
    .map(([_, country]) => country)
    .filter((country) => supportedCountries.includes(country?.toUpperCase()));
  return supportedCountry?.toUpperCase();
};

export const getFirstSupportedCountryByLanguage = () => {
  const languages = navigator.languages || [navigator.language];
  const [supportedCountry] = languages
    .map((language) => language.split('-'))
    .map(([lng]) => lng)
    .map(getCountryByLanguage)
    .filter((country) => supportedCountries.includes(country?.toUpperCase()));
  return supportedCountry?.toUpperCase();
};

export const getCountryByIpInfo = (): Promise<SupportedCountry | null> => fetch('https://ipinfo.io/country?token=91ee4192d19e35')
  .then((response) => response.text())
  .then((country) => country.trim())
  .then((country) => (supportedCountries.includes(country) ? country : null))
  .catch(() => null);

export const getPublicCountry = (): SupportedCountry => getFirstSupportedCountry()
  || getFirstSupportedCountryByLanguage()
  || 'GB';

export const usePublicCountry = (): SupportedCountry => {
  const [country, setCountry] = useState<string>(null);
  useEffect(() => {
    getCountryByIpInfo()
      .then((data) => setCountry(data || getPublicCountry()));
  }, []);
  return country;
};
