import { SortDirection } from "@/api/search";
import {
  DEFAULT_DIRECTION,
  DEFAULT_PROPS,
  DEFAULT_SEARCH_API_URL,
} from "@/constants/search";
import { RelationType } from "@bit/together-price.core.components.add-user-modal";
import { atom } from "recoil";
import handleUrlParams from "../utils/handleUrlParams";

export const pathState = atom({
  key: "search/pathState",
  default: "/search",
});

export const addUserModalState = atom({
  key: "search/addUserModalState",
  default: 0,
});

export type UserRelation = {
  userId: number;
  relation: RelationType;
};

export const userRelationState = atom<UserRelation[]>({
  key: "search/userRelationState",
  default: [],
});

// query handler states
export const queryState = atom({
  key: "search/queryState",
  default: "",
  effects: [handleUrlParams.bind(this, "q")],
});

export const serviceIdState = atom({
  key: "search/serviceIdState",
  default: 0,
  effects: [handleUrlParams.bind(this, "serviceIdState")],
});

export const tagsState = atom({
  key: "search/tagsState",
  default: [],
  effects: [handleUrlParams.bind(this, "tags")],
});

export const categoriesState = atom({
  key: "search/categoriesState",
  default: [],
  effects: [handleUrlParams.bind(this, "categories")],
});

export const propsState = atom({
  key: "search/propsState",
  default: DEFAULT_PROPS,
  effects: [handleUrlParams.bind(this, "props")],
});

export const directionState = atom<SortDirection>({
  key: "search/directionState",
  default: DEFAULT_DIRECTION,
  effects: [handleUrlParams.bind(this, "direction")],
});

export const filtersState = atom({
  key: "search/filtersState",
  default: [],
  effects: [handleUrlParams.bind(this, "filters")],
});

export const apiState = atom({
  key: "search/apiState",
  default: DEFAULT_SEARCH_API_URL,
  effects: [handleUrlParams.bind(this, "api")],
});

export const marketCountryState = atom({
  key: "search/marketCountryState",
  default: "",
  effects: [handleUrlParams.bind(this, "marketCountry")],
});
