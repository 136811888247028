import translation from './translation.json';
import androidAppBanner from '@bit/together-price.core.banners.android-app-banner/i18n/en.json'
import crossSellingJoinerCountDownBanner from '@bit/together-price.core.banners.cross-selling-joiner-count-down/i18n/en.json';
import discoverViralLoopBanner from '@bit/together-price.core.banners.discover-viral-loop/i18n/en.json';
import findYourFriendsBanner from '@bit/together-price.core.banners.find-your-friends/i18n/en.json';
import freeForYouBanner from '@bit/together-price.core.banners.free-for-you/i18n/en.json'
import oneMillionPromoBanner from '@bit/together-price.core.banners.one-million-promo/i18n/en.json'
import tpStoreBanner from '@bit/together-price.core.banners.tp-store-banner/i18n/en.json'

export default {
    translation,
    AndroidAppBanner: androidAppBanner,
    CrossSellingJoinerCountDownBanner: crossSellingJoinerCountDownBanner,
    DiscoverViralLoopBanner: discoverViralLoopBanner,
    FindYourFriendsBanner: findYourFriendsBanner,
    FreeForYouBanner: freeForYouBanner,
    OneMillionPromoBanner: oneMillionPromoBanner,
    TPStoreBanner: tpStoreBanner
}