import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { alpha, Grid, Hidden, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import useShouldShowServiceCard from '@/hooks/search/useShouldShowServiceCard';
import useCurrentService from '@/hooks/search/useCurrentService';
import BackButton from '@bit/together-price.core.components.back-button';
import useSearchTitle from '@/hooks/search/useSearchTitle';
import SearchControls, {
  SearchControlsProps,
} from '@/components/SearchControls';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  directionState,
  filtersState,
  propsState,
} from '@/recoil/atoms/search';
import { GROUPS_FILTERS, GROUPS_SORTS } from '@/constants/search';
import useFilteredList from '@/hooks/useFilteredList';
import { SearchServiceCard } from './SearchServiceCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(8, 13),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      flexDirection: 'column',
      padding: 0,
    },
  },
  withService: {
    background: alpha(theme.palette.primary.light, 0.3),
    borderBottomLeftRadius: theme.spacing(10),
    borderBottomRightRadius: theme.spacing(10),
    paddingTop: theme.spacing(10),
    marginTop: theme.spacing(-10),
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    width: 'calc(100% + 32px)',
  },
  serviceCardDesktop: {
    width: 'max-content',
  },
  searchServiceCard: {
    width: 'max-content',
    padding: 0,
    paddingLeft: theme.spacing(5),
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  title: {},
}));

export type SearchHeaderProps = {
  marketCountry: string;
  usePublicNetwork?: boolean;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({
  marketCountry,
  usePublicNetwork,
}) => {
  const classes = useStyles();
  const service = useCurrentService(marketCountry);
  const shouldShowServiceCard = useShouldShowServiceCard(service);
  const history = useHistory();
  const [title, showTitle] = useSearchTitle(marketCountry, usePublicNetwork);
  const setDirection = useSetRecoilState(directionState);
  const [props, setProps] = useRecoilState(propsState);
  const [filters, setFilters] = useRecoilState(filtersState);

  const filteredFilters = useFilteredList<string>(filters, GROUPS_FILTERS, []);

  const onBack = useCallback(() => {
    if (history.length > 0) {
      return history.goBack();
    }
    return history.push('/');
  }, [history]);

  const updateFilters = useCallback(
    (filter, checked) => {
      setFilters(
        checked ? [...filters, filter] : filters.filter((f) => f !== filter)
      );
    },
    [filters, setFilters]
  );

  const updateSorts = useCallback(
    (sort, checked) => {
      if (checked) {
        setProps([sort]);
        setDirection('desc');
        return;
      }
      setProps([]);
      setDirection(null);
    },
    [setDirection, setProps]
  );

  const updateControls: SearchControlsProps['onChange'] = useCallback(
    (controlKey, checked, type) => {
      if (type === 'filter') {
        return updateFilters(controlKey, checked);
      }
      return updateSorts(controlKey, checked);
    },
    [updateFilters, updateSorts]
  );

  return (
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          className={classes.serviceCardDesktop}
        >
          {(shouldShowServiceCard || showTitle) && (
            <>
              <BackButton onBack={onBack} />
              {shouldShowServiceCard ? (
                <SearchServiceCard
                  {...service}
                  className={classes.searchServiceCard}
                />
              ) : (
                <Grid className={classes.title}>{title}</Grid>
              )}
            </>
          )}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          className={clsx({
            [classes.withService]: shouldShowServiceCard,
          })}
        >
          {shouldShowServiceCard && <SearchServiceCard {...service} />}
        </Grid>
      </Hidden>
      <Grid className={classes.filterWrapper}>
        <SearchControls
          onChange={updateControls}
          allSorts={GROUPS_SORTS}
          allFilters={GROUPS_FILTERS}
          value={[...filteredFilters, ...props]}
        />
      </Grid>
    </Grid>
  );
};

export default SearchHeader;
