import { Languages } from '@dev-togetherprice/hooks.use-register-translation';
import { useEffect } from 'react';
import { useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';

//TODO: quick fix for i18n issue, to be removed
function useRegisteredTranslation<N extends string>(
    ns: N,
    translations: Record<Languages, any>,
    options?: UseTranslationOptions
): UseTranslationResponse<N> {
    const translation = useTranslation(ns, options);

    useEffect(() => {
        if (!translation.i18n.hasResourceBundle(translation.i18n.language, ns)) {
            translation.i18n.addResourceBundle(
                translation.i18n.language,
                ns,
                translations[translation.i18n.language as Languages]
            );
        }
    }, [ns, translation.i18n, translation.i18n.language, translations]);

    return translation;
}

export default useRegisteredTranslation;
