import { Badge, createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import TpStarIcon from '@dev-togetherprice/icons.star';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const RatingBadge = withStyles(
  () =>
    createStyles({
      root: {
        position: 'relative',
        width: 'fit-content',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
      },
      badge: {
        width: 'fit-content',
        height: 'auto',
        transform: 'none',
        padding: 0,
      },
    }),
  { name: 'TpRatingBadge' }
)(Badge);

const StarIcon = withStyles(
  () =>
    createStyles({
      root: {
        fill: '#FEA41D',
        marginBottom: '-1.2px',
        marginRight: '-1.2px',
        stroke: 'white',
      },
    }),
  { name: 'TpMfStarIcon' }
)(TpStarIcon);

type RatingProps = {
  value: number;
};
const Rating: React.FC<RatingProps> = ({ value = 0 }) => (
  <Typography
    component='span'
    variant='overline'
    align='center'
    color='inherit'
    style={{
      letterSpacing: '0.15px',
      fontWeight: 500,
      height: '29.5px',
    }}
  >
    {value.toFixed(1)} <StarIcon fontSize='inherit' />
  </Typography>
);

const useStyles = makeStyles(
  (theme) => ({
    withRating: {
      paddingRight: theme.spacing(13),
    },
    title: {
      fontSize: '14px',
      color: 'inherit',
      fontWeight: 400,
    },
  }),
  { name: 'AdminFullName' }
);
export type AdminFullNameProps = {
  fullName: string;
  ratings?: number;
};
const AdminFullName: React.FC<AdminFullNameProps> = ({
  fullName,
  ratings = 0,
}) => {
  const classes = useStyles();
  return (
    <RatingBadge
      className={clsx({
        [classes.withRating]: !!ratings,
      })}
      invisible={!ratings}
      badgeContent={<Rating value={ratings} />}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
    >
      <Typography
        component='span'
        variant='subtitle1'
        noWrap
        className={classes.title}
      >
        {fullName}
      </Typography>
    </RatingBadge>
  );
};

export default AdminFullName;
