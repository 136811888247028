import React, { ComponentType, PureComponent } from 'react';
import { MutableSnapshot, RecoilRoot } from 'recoil';

function withRecoilRoot<T>(
  WrappedComponent: ComponentType<T>,
  initializer?: (mutableSnapshot: MutableSnapshot) => void
): ComponentType<T> {
  return class extends PureComponent<T> {
    render() {
      return (
        <RecoilRoot {...(initializer ? { initializeState: initializer } : {})}>
          <WrappedComponent {...(this.props as T)} />
        </RecoilRoot>
      );
    }
  };
}
export default withRecoilRoot;
