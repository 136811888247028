import React from 'react';
import useSharingPreviewPanelStyles from '@/components/SharingPreviewPanel/useSharingPreviewPanelStyles';
import useSharingPreviewStep from '@/components/SharingPreviewPanel/useSharingPreviewStep';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Button, Grid, styled } from '@material-ui/core';
import IconButton from '@dev-togetherprice/components.icon-button';
import BackIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { Trans } from 'react-i18next';
import { Money } from '@dev-togetherprice/monetary';
import { useNavigateToUrl } from '@dev-togetherprice/components.app-context';
import { useSharingPreviewPanelState } from './SharingPreviewPanel';

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '27px',
  color: theme.palette.primary.main,
}));

const List = styled('ul')({
  paddingLeft: '20px',
  listStyle: 'disc',
});

const ListItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(4, 0),
}));

const ListItemTitle = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
});

const ListItemDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.primary.main,
  paddingTop: theme.spacing(3),
}));

const Strong = styled('span')(({ theme }) => ({
  fontWeight: 500,
}));

const BottomActionContainer = styled(Container)(({ theme }) => ({
  borderRadius: '40px 40px 0px 0px',
  background: theme.palette.common.white,
  padding: theme.spacing(7, 4),
  position: 'fixed',
  bottom: 0,
  width: '100%',
  maxWidth: '100%',
}));

const BottomActionPlaceholder = styled('div')(({ theme }) => ({
  height: 80,
  width: '100%',
}));

const HowWork: React.FC = () => {
  const classes = useSharingPreviewPanelStyles();
  const [, setStep] = useSharingPreviewStep();
  const { value: post } = useSharingPreviewPanelState();
  const navigateToUrl = useNavigateToUrl();
  const { monthlyPrice, sharingPostId, postTitle } = post;
  const price = Money.toString(monthlyPrice);
  const serviceName = postTitle;
  const tpassword = ![undefined, 'MISSING', 'UNKNOWN'].includes(
    post.secretStatus
  );

  return (
    <>
      <Container className={classes.content}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <IconButton
              color='primary'
              onClick={() => setStep(undefined, 'replaceIn')}
              style={{ padding: 0 }}
            >
              <BackIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'scroll',
            }}
          >
            <Title variant='h2'>
              <Trans i18nKey='SharingPreviewPanel.HowWork.title'>
                Come funziona la condivisione
              </Trans>
            </Title>
            <List>
              <ListItem>
                <ListItemTitle variant='h3'>
                  <Trans i18nKey='SharingPreviewPanel.HowWork.item.0.title'>
                    Partecipare è semplice!
                  </Trans>
                </ListItemTitle>
                <ListItemDescription variant='body2'>
                  <Trans
                    i18nKey='SharingPreviewPanel.HowWork.item.0.description'
                    values={{ price }}
                  />
                </ListItemDescription>
              </ListItem>
              <ListItem>
                <ListItemTitle variant='h3'>
                  <Trans i18nKey='SharingPreviewPanel.HowWork.item.1.title'>
                    Cosa ottieni partecipando
                  </Trans>
                </ListItemTitle>
                <ListItemDescription variant='body2'>
                  <Trans
                    i18nKey='SharingPreviewPanel.HowWork.item.1.description'
                    tOptions={{ context: tpassword ? 'tpassword' : undefined }}
                    values={{ serviceName }}
                    components={{ 2: <Strong /> }}
                  />
                </ListItemDescription>
              </ListItem>
              <ListItem>
                <ListItemTitle variant='h3'>
                  <Trans i18nKey='SharingPreviewPanel.HowWork.item.2.title'>
                    Info aggiuntive
                  </Trans>
                </ListItemTitle>
                <ListItemDescription variant='body2'>
                  <Trans i18nKey='SharingPreviewPanel.HowWork.item.2.description1'>
                    La condivisione non ha alcun vincolo. Potrai decidere di
                    uscire dal gruppo di condivisione in qualsiasi momento.
                  </Trans>
                </ListItemDescription>
                <ListItemDescription variant='body2'>
                  <Trans i18nKey='SharingPreviewPanel.HowWork.item.2.description2'>
                    Ti ricordiamo inoltre che in caso di problemi hai 25 giorni
                    di tempo per richiedere il rimborso della tua quota.
                  </Trans>
                </ListItemDescription>
              </ListItem>
            </List>
            <BottomActionPlaceholder />
          </Grid>
        </Grid>
      </Container>
      <BottomActionContainer>
        <Button
          color='primary'
          variant='contained'
          fullWidth
          size='large'
          onClick={() => navigateToUrl(`/post/${sharingPostId}/fast-checkout`)}
          disableElevation
          className={classes.button}
        >
          <Trans i18nKey='SharingPreviewPanel.join'>Partecipa</Trans>
        </Button>
      </BottomActionContainer>
    </>
  );
};

export default HowWork;
