import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useStyles } from './SharingCard';

type Props ={
  customHeight?: number
};

const SharingCardSkeleton: FC<Props> = ({ customHeight = 280 }) => {
  const classes = useStyles({ customHeight });
  return (
    <Paper className={classes.root} elevation={0} style={{ height: '100%' }}>
      <Container className={classes.avatarContainer}>
        <Skeleton
          variant='circle'
          width='78px'
          height='78px'
          style={{ zIndex: 3 }}
        />
      </Container>
      <Paper component={Container} className={classes.header} elevation={0}>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.ratingsRow} />
            <Grid item xs={12}>
              <Skeleton variant='text' />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Typography
                component='div'
                variant='h1'
                align='center'
                className={classes.title}
                noWrap
              >
                <Skeleton variant='text' width='150px' />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.joinersRow}>
              <AvatarGroup
                max={3}
                style={{
                  margin: 'auto',
                  width: 'fit-content',
                  justifyContent: 'center',
                }}
              >
                <Skeleton variant='circle' height='32px' width='32px' />
                <Skeleton variant='circle' height='32px' width='32px' />
                <Skeleton variant='circle' height='32px' width='32px' />
              </AvatarGroup>
            </Grid>
            <Grid item xs={12} container justify='space-between' spacing={2}>
              <Grid item xs>
                <Typography
                  variant='overline'
                  display='block'
                  align='center'
                  color='primary'
                  className={classes.infoTitle}
                >
                  <Skeleton variant='text' />
                </Typography>
                <Typography component='div' variant='h1' align='center'>
                  <Skeleton variant='text' />
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  variant='overline'
                  display='block'
                  align='center'
                  color='primary'
                  className={classes.infoTitle}
                >
                  <Skeleton variant='text' />
                </Typography>
                <Typography
                  component='div'
                  variant='h1'
                  align='center'
                  color='primary'
                >
                  <Skeleton variant='text' />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Container>
      </Paper>
    </Paper>
  );
};

export default SharingCardSkeleton;
