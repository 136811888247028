import { Service } from '@/api/service';
import { useMemo } from 'react';

const useShouldShowServiceCard = (service: Service): boolean => {
  const showServiceCard = useMemo(
    () => !!service && service.tag !== 'custom' && service.showServiceCard,
    [service]
  );

  return showServiceCard;
};

export default useShouldShowServiceCard;
