import React, { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripeInfo } from '@bit/together-price.core.api.financial';

const StripeWrapper: FC = ({ children }) => {
  const { data: stripeInfo } = useStripeInfo();
  const stripePromise = loadStripe(stripeInfo.apiKey);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeWrapper;
