import React from "react";
import { Route, Switch } from "react-router-dom";

import StripeWrapper from "@/components/StripeWrapper";
import ShortFunnelPayment from "./ShortFunnelPayment/ShortFunnelPayment";
import ShortFunnelSearch from "./ShortFunnelSearch";
import ShortFunnelSuccess from "./ShortFunnelPaymentResult/ShortFunnelSuccess";
import ShortFunnelFail from "./ShortFunnelPaymentResult/ShortFunnelFail";

export function ShortFunnel() {
  return (
    <Switch>
      <Route
        path={[
          "/gray_key_groups/:service",
          "/green_key_groups/:service",
          "/unavailable_group/:service",
        ]}
      >
        <ShortFunnelSearch />
      </Route>
      <Route
        path={[
          "/green_key_checkout/:service/post/:postId",
          "/gray_key_checkout/:service/post/:postId",
        ]}
      >
        <StripeWrapper>
          <ShortFunnelPayment />
        </StripeWrapper>
      </Route>
      <Route path="/success_payment/post/:postId">
        <ShortFunnelSuccess />
      </Route>
      <Route path="/failed_payment/post/:postId">
        <ShortFunnelFail />
      </Route>
    </Switch>
  );
}
