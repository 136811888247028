import { searchQuery } from "@/api/search";
import {
  apiState,
  categoriesState,
  directionState,
  filtersState,
  marketCountryState,
  propsState,
  queryState,
  serviceIdState,
  tagsState,
} from "@/recoil/atoms/search";
import { SortDirection } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

type UseSearchResultQueryOptions = {
  q?: string;
  serviceId?: number;
  filters?: string[];
  searchApiUrl?: string;
  tags?: string[];
  categories?: string[];
  props?: string[];
  direction?: SortDirection | string;
  marketCountry?: string;
};

const useSearchResultQuery = (
  options?: UseSearchResultQueryOptions
): string => {
  const q = useRecoilValue(queryState);
  const serviceId = useRecoilValue(serviceIdState);
  const filters = useRecoilValue(filtersState);
  const searchApiUrl = useRecoilValue(apiState);
  const tags = useRecoilValue(tagsState);
  const categories = useRecoilValue(categoriesState);
  const props = useRecoilValue(propsState);
  const direction = useRecoilValue(directionState);
  const marketCountry = useRecoilValue(marketCountryState);

  const getQuery = () => {
    const value = searchQuery(options?.searchApiUrl ?? searchApiUrl, {
      q: options?.q ?? q,
      serviceId: options?.serviceId ?? serviceId,
      marketCountry: options?.marketCountry ?? marketCountry,
      filters: options?.filters ?? filters,
      props: options?.props ?? props,
      tags: options?.tags ?? tags,
      categories: options?.categories ?? categories,
      // @ts-ignore
      direction: options?.direction ?? direction,
    });

    return value;
  };

  // default query
  const [query, setQuery] = useState(getQuery());

  useEffect(() => {
    const timer = setTimeout(() => {
      // avoid big react calculation if user type in search box
      setQuery(getQuery());
    }, 800);

    return () => clearTimeout(timer);
  });

  return query;
};

export default useSearchResultQuery;
