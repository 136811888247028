import React, { FC, ReactNode, useMemo } from 'react';
import theme from '@dev-togetherprice/theme';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';

export type MuiThemeProviderProps = {
  children?: ReactNode;
  seed?: string;
  prefix?: string;
};

const MuiThemeProvider: FC<MuiThemeProviderProps> = ({
  children,
  seed = '@together-price/mf-network',
  prefix = 'tp-n',
}) => {
  const generateClassName = useMemo(
    () =>
      createGenerateClassName({
        productionPrefix: prefix,
        seed,
      }),
    [prefix, seed]
  );

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

export default MuiThemeProvider;
