import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ChatHelp = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
    <path d="M20 13.5352C20 10.62 18.0357 8.07297 15.2658 7.30545C15.0917 3.24768 11.7366 0 7.63672 0C3.42575 0 0 3.42575 0 7.63672C0 9.00909 0.365295 10.3453 1.05896 11.5184L0.0280762 15.2452L3.75504 14.2145C4.83337 14.8521 6.0498 15.2113 7.3053 15.2655C8.07266 18.0356 10.6198 20 13.5352 20C14.6988 20 15.8304 19.6901 16.8246 19.1013L19.9718 19.9718L19.1013 16.8246C19.6901 15.8304 20 14.6988 20 13.5352ZM3.93997 12.9474L1.70883 13.5646L2.32605 11.3335L2.18536 11.1134C1.52237 10.0761 1.17188 8.8739 1.17188 7.63672C1.17188 4.07196 4.07196 1.17188 7.63672 1.17188C11.2015 1.17188 14.1016 4.07196 14.1016 7.63672C14.1016 11.2015 11.2015 14.1016 7.63672 14.1016C6.39954 14.1016 5.19745 13.7511 4.16 13.0881L3.93997 12.9474ZM18.2912 18.2912L16.6356 17.8331L16.4145 17.977C15.5574 18.5338 14.5616 18.8281 13.5352 18.8281C11.2439 18.8281 9.2305 17.3466 8.51715 15.2223C12.0258 14.8178 14.8178 12.0258 15.2225 8.517C17.3466 9.2305 18.8281 11.2439 18.8281 13.5352C18.8281 14.5616 18.5338 15.5574 17.977 16.4145L17.8331 16.6356L18.2912 18.2912Z" />
    <path d="M7.05078 10.5859H8.22266V11.7578H7.05078V10.5859Z" />
    <path d="M8.80859 5.85938C8.80859 6.19217 8.6731 6.49933 8.42712 6.7244L7.05078 7.98416V9.41406H8.22266V8.50021L9.21829 7.58896C9.70261 7.14569 9.98047 6.51535 9.98047 5.85938C9.98047 4.56696 8.92914 3.51562 7.63672 3.51562C6.3443 3.51562 5.29297 4.56696 5.29297 5.85938H6.46484C6.46484 5.21317 6.99051 4.6875 7.63672 4.6875C8.28293 4.6875 8.80859 5.21317 8.80859 5.85938Z" />
  </SvgIcon>
);

export default ChatHelp;
