export const DEFAULT_SEARCH_API_URL = '/carousel/search';
export const DEFAULT_PUBLIC_SEARCH_API_URL = '/carousel/publicSearch';
export const DEFAULT_PROPS = ['admin.trust'];
export const DEFAULT_DIRECTION = 'desc';

export const GROUPS_FILTERS = [
  'identity_verified',
  'validated_credentials',
  'online_now',
  'today_last_access',
  'six_month_member',
  'one_slot_available',
];

export const GROUPS_SORTS = ['admin.trust', 'admin.ratings', 'createdAt'];

export const PEOPLE_FILTERS = ['phone_book', 'platform_users', 'contacts'];
