import { usePublicCountry } from '@/api/public-country';
import Search from '@/App/Routes/Search';
import AdminInfoPanel from '@/components/AdminInfoPanel';
import NotificationsContainer from '@/components/NotificationsContainer';
import {
  useApp,
  usePublic,
} from '@dev-togetherprice/components.app-context';
import React, { lazy } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ShortFunnel } from './ShortFunnel';

// Pages
const Home = lazy(() => import('@/App/Routes/Home'));
const PublicHome = lazy(() => import('@/App/Routes/Home/PublicHome'));
const Create = lazy(() => import('@/App/Routes/Create'));
const CreateFlow = lazy(() => import('@/App/Routes/Create/CreateFlow'));
const UserPosts = lazy(() => import('@/App/Routes/UserPosts'));
const Sharings = lazy(() => import('@/App/Routes/Sharings'));

// Components
const RegisterOrLoginPopup = lazy(
  () => import('@/components/RegisterOrLoginPopup')
);
const SharingPreviewPanel = lazy(
  () => import('@/components/SharingPreviewPanel')
);



const PrivateRoutes = () => (
  <>
    <Switch>
      <Route path={[
        "/gray_key_groups/:service", 
        "/green_key_groups/:service", 
        "/green_key_checkout/:service/post/:postId", 
        "/gray_key_checkout/:service/post/:postId", 
        "/unavailable_group/:service",
        "/success_payment/post/:postId",
        "/failed_payment/post/:postId",
        ]}
      >
        <ShortFunnel />
      </Route>
      <Route path='/sharings/:route'>
        <Sharings />
      </Route>
      <Route path='/search'>
        <Search />
      </Route>
      <Route path='/create/:route'>
        <CreateFlow />
      </Route>
      <Route path='/create'>
        <Create />
      </Route>
      <Route path='/profile/:id/posts'>
        <UserPosts />
      </Route>
      <Route path='/'>
        <Home />
      </Route>
    </Switch>
    <AdminInfoPanel />
  </>
);

const PublicRoutes = () => {
  const country = usePublicCountry();
  if (!country) return <></>;
  return (
    <>
      <Switch>
        <Route path='/search'>
          <Search marketCountry={country} usePublicNetwork />
        </Route>
        <Route path='/'>
          <PublicHome marketCountry={country} />
        </Route>
      </Switch>
      <RegisterOrLoginPopup />
      <SharingPreviewPanel />
    </>
  );
};

export type RoutesProps = {};
const Routes: React.FC<RoutesProps> = () => {
  const publicNetwork = usePublic();
  const { basename } = useApp();

  return (
    <Router basename={basename}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <NotificationsContainer />
        {!publicNetwork && <PrivateRoutes />}
        {publicNetwork && <PublicRoutes />}
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;
