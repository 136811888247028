import React from 'react';
import { Trans } from 'react-i18next';
import { PostFrequency } from '../../api/search';

export type SharingCardFrequencyProps = {
  variant: PostFrequency,
  discounted?: boolean,
};
const SharingCardFrequency: React.FC<SharingCardFrequencyProps> = ({
  variant,
  discounted,
}) => {
  if (discounted) {
    return (
      <Trans
        i18nKey="SharingCard.frequency-one"
        tOptions={{
          context: variant,
        }}
      >
        {variant}
      </Trans>
    );
  }
  return (
    <Trans
      i18nKey="SharingCard.frequency"
      tOptions={{
        context: variant,
      }}
    >
      {variant}
    </Trans>
  );
};

export default SharingCardFrequency;
