import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import en from './en';
import es from './es';
import it from './it';
import pt from './pt';

const instance = i18next
  .createInstance()
  .use(initReactI18next)
  .use(intervalPlural);

instance.init({
  resources: {
    en,
    es,
    it,
    pt,
  },
  lng: 'it',
  fallbackLng: 'it',
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added',
  },
});

export default instance;
