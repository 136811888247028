import React from 'react';
import useSharingPreviewPanelStyles from '@/components/SharingPreviewPanel/useSharingPreviewPanelStyles';
import useSharingPreviewStep from '@/components/SharingPreviewPanel/useSharingPreviewStep';
import Container from '@material-ui/core/Container';
import { Grid, styled } from '@material-ui/core';
import IconButton from '@bit/together-price.core.components.icon-button';
import BackIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { Money } from '@bit/together-price.core.monetary';
import { Trans, useTranslation } from 'react-i18next';
import { useServicePlanInfo } from '@/api/service';
import { useSharingPreviewPanelState } from './SharingPreviewPanel';

const Title = styled('h2')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '27px',
  color: theme.palette.primary.main,
}));

const Description = styled('p')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
}));

const SectionTitle = styled('span')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
});

const List = styled('ul')({
  paddingLeft: '20px',
  listStyle: 'disc',
});

const ListItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const ListItemTitle = styled('span')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
});

const InfoService: React.FC = () => {
  const classes = useSharingPreviewPanelStyles();
  const [, setStep] = useSharingPreviewStep();
  const { value: post } = useSharingPreviewPanelState();
  const { i18n } = useTranslation();
  const serviceInfo = useServicePlanInfo(
    post.serviceId !== 1
      ? {
          serviceId: post.serviceId,
          language: i18n.language.toUpperCase(),
        }
      : null
  );
  const { totalAvailability, postTitle } = post;
  const serviceName = postTitle;
  const slots = (serviceInfo?.totalAvailability || totalAvailability) + 1;
  const description = serviceInfo?.description;
  const price = Money.toString(post.monthlyPrice);
  const website = serviceInfo?.link;
  return (
    <>
      <Container className={classes.content}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <IconButton
              color='primary'
              onClick={() => setStep(undefined, 'replaceIn')}
              style={{ padding: 0 }}
            >
              <BackIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              maxHeight: 'calc(100vh - 200px)',
              overflow: 'scroll',
            }}
          >
            <Title>
              <Trans
                i18nKey='SharingPreviewPanel.InfoService.title'
                values={{ serviceName }}
              />
            </Title>
            {description && <Description>{description}</Description>}
          </Grid>
          <Grid item xs={12}>
            <SectionTitle>
              <Trans i18nKey='SharingPreviewPanel.InfoService.item.0.title'>
                Condivisibile tra
              </Trans>
            </SectionTitle>
            <List>
              <ListItem>
                <ListItemTitle>
                  <Trans
                    i18nKey='SharingPreviewPanel.InfoService.item.0.li'
                    values={{ slots }}
                  />
                </ListItemTitle>
              </ListItem>
            </List>
            <SectionTitle>
              <Trans i18nKey='SharingPreviewPanel.InfoService.item.1.title'>
                Costo condivisione
              </Trans>
            </SectionTitle>
            <List>
              <ListItem>
                <ListItemTitle>
                  <Trans
                    i18nKey='SharingPreviewPanel.InfoService.item.1.li'
                    values={{ price }}
                  />
                </ListItemTitle>
              </ListItem>
            </List>
            {website && (
              <>
                <SectionTitle>
                  <Trans i18nKey='SharingPreviewPanel.InfoService.item.2.title'>
                    Sito web
                  </Trans>
                </SectionTitle>
                <List>
                  <ListItem>
                    <ListItemTitle>
                      <a href={`://${website}`} target='_blank'>
                        {website}
                      </a>
                    </ListItemTitle>
                  </ListItem>
                </List>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InfoService;
