import { autoFocusedSearchState } from '@/recoil/atoms/app';
import { queryState } from '@/recoil/atoms/search';
import {
  ChangeEventHandler,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

type SearchComponentType = HTMLTextAreaElement | HTMLInputElement;

export type UseSearchInputResponse = {
  defaultValue: string;
  handleSearchChange: ChangeEventHandler<SearchComponentType>;
};

const useSearchInput = (): UseSearchInputResponse => {
  const history = useHistory();
  const [defaultValue, setDefaultValue] = useState('');
  const setIsFocused = useSetRecoilState(autoFocusedSearchState);

  const setQuery = useSetRecoilState(queryState);

  const handleSearchChange = useCallback(
    (e: FormEvent<SearchComponentType>) => {
      const value = e.currentTarget.value as string;
      setIsFocused(true);
      setQuery(value);
    },
    []
  );

  // we should not have deps because of default value
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    setDefaultValue(urlSearchParams.get('q') || '');
  }, []);

  return { defaultValue, handleSearchChange };
};

export default useSearchInput;
