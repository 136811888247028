import { BannerTypes } from '@bit/together-price.core.banners.tp-store-banner';
import { atom } from 'recoil';

export const usableBannersState = atom({
  key: 'app/usableBannersState',
  default: [],
});

export const currentBannerState = atom<BannerTypes>({
  key: 'app/currentBannerState',
  default: undefined,
});

export const autoFocusedSearchState = atom({
  key: 'app/autoFocusedSearchState',
  default: false
});
