import React, { ReactNode, useMemo } from 'react';
import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
  LinkProps,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Money, MoneyLike } from '@dev-togetherprice/monetary';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { getImageWithFullPath } from '@/helpers/get-image-with-full-path';
import { useApplicationSettings } from '@dev-togetherprice/api.settings';
import { fetchNetwork } from '@dev-togetherprice/api.services';
import AdminAvatar, {
  AdminAvatarProps,
} from '@dev-togetherprice/components.admin-avatar';
import LazyAvatar from '@dev-togetherprice/components.lazy-avatar';
import { useNavigateToUrl } from '@dev-togetherprice/components.app-context';
import { PostFrequency, PostSecretStatus } from '@/api/search';
import useLazyImage from '@/helpers/use-lazy-image';
import AdminFullName from '../AdminFullName';
import JoinersAvatarGroup from '../JoinersAvatarGroup';
import SharingPostTitle from '../SharingPostTitle';
import SharingCardFrequency from './SharingCardFrequency';

export type SharingCardPostAdmin = {
  fullName: string;
  profileUrl?: string;
  profilePictureUrl?: string;
  ratings?: number;
  trust?: number;
  verified?: boolean;
};
export type SharingCardPostJoiner = {
  fullName: string;
  profilePictureUrl?: string;
};
export type SharingCardProps = {
  id?: string;
  tag?: string;
  sharingPostId: string | number;
  title: string;
  postUrl?: string;
  cover?: string;
  price: MoneyLike;
  discounted?: boolean;
  frequency: PostFrequency;
  freeAvailability: number;
  admin: SharingCardPostAdmin;
  joiners?: SharingCardPostJoiner[];
  secretStatus?: PostSecretStatus;
  onClick?: LinkProps['onClick'];
  onClickAdmin?: AdminAvatarProps['onClick'];
  customHeight?: number;
  noOpacity?: boolean;
  checkIfPostMustBeHidden?: boolean;
  children?: ReactNode;
};

type StylesProps = {
  cover?: string;
  customHeight?: number;
  noOpacity?: boolean;
  noClickable?: boolean;
};
export const useStyles = makeStyles<Theme, StylesProps>(
  (theme) =>
    createStyles({
      root: {
        width: '256px',
        height: ({ customHeight }) => customHeight ?? '280px',
        display: 'block',
        position: 'relative',
        paddingTop: '50px',
        background: 'transparent',
        textDecoration: 'none',
        marginTop: theme.spacing(4),
        zIndex: 1,
      },
      full: {
        position: 'absolute',
        top: '1.5rem',
        left: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        padding: '2px .3rem',
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
      },
      zeroFreeAvailability: {
        opacity: ({ noOpacity }) => !noOpacity && '0.7',
        '&$header, &$avatarContainer': {
          opacity: ({ noOpacity }) => !noOpacity && '0.7',
        },
      },
      header: {
        [theme.breakpoints.up('md')]: {
          cursor: ({ noClickable }) => (noClickable ? 'default' : 'pointer'),
          '-webkit-tap-highlight-color': 'transparent',
        },
        zIndex: -1,
        position: 'relative',
        width: '100%',
        height: ({ customHeight = 280 }) => `${customHeight - 50}px`,
        background: theme.palette.primary.light,
        borderRadius: '20px',
        boxShadow: '0px 0px 11px rgba(85, 93, 104, 0.26)',
        '&::before': {
          content: '" "',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          width: '100%',
          height: '100px',
          backgroundColor: theme.palette.grey.A100,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          borderBottomLeftRadius: '48px',
          borderBottomRightRadius: '48px',
        },
        '&::after': {
          content: '" "',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          width: '100%',
          height: '100px',
          background:
            'linear-gradient(180deg, rgba(150, 64, 181, 0.13) 0%, rgba(103, 20, 132, 0.4) 38.02%, #9640B5 100%)',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          borderBottomLeftRadius: '48px',
          borderBottomRightRadius: '48px',
        },
        '& > *': {
          zIndex: 1,
        },
      },
      withCover: {
        '&::before': {
          backgroundImage: ({ cover }) =>
            cover ? `url("${cover}")` : undefined,
          backgroundSize: 'cover',
        },
      },
      avatarContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
      },
      avatar: {
        width: '74px',
        height: '74px',
      },
      title: {
        width: 'fit-content',
        padding: '7px 20px',
        background: theme.palette.primary.light,
        borderRadius: '40px',
        margin: 'auto',
        minWidth: '100px',
        maxWidth: '220px',
        textOverflow: 'ellipses',
        fontSize: '19px',
      },
      postTitleRow: {
        textAlign: 'center',
      },
      joinersRow: {
        minHeight: '36px',
      },
      divider: {
        backgroundColor: theme.palette.primary.main,
        height: '60%',
        maxHeight: '31px',
        opacity: 0.7,
        width: '0.5px',
        marginTop: '1.5em',
      },
      infoTitle: {
        mixBlendMode: 'normal',
        letterSpacing: '0.5px',
        fontWeight: 'normal',
        fontSize: '12px',
      },
      ratingsRow: {
        minHeight: '45px',
      },
      adminFullNameRow: {
        textAlign: 'center',
        color: theme.palette.common.white,
      },
      secretKey: {
        marginBottom: -theme.spacing(1.5),
      },
      primaryDark: {
        color: theme.palette.primary.dark,
      },
      price: {
        fontWeight: 600,
        fontSize: '20px',
        fontFamily: 'Poppins',
      },
    }),
  { name: 'SharingCard' }
);
export const shouldGoPost = async (id) => {
  const res = await fetchNetwork('/checkIfPosMustBeHidden', {
    method: 'POST',
    body: `${id}`,
  });
  return !res;
};
const SharingCard: React.FC<SharingCardProps> = ({
  children,
  id,
  sharingPostId,
  title,
  postUrl,
  cover,
  price,
  tag,
  discounted = false,
  frequency,
  freeAvailability,
  secretStatus,
  admin,
  joiners,
  onClick,
  onClickAdmin,
  noOpacity,
  checkIfPostMustBeHidden,
  customHeight,
}) => {
  const navigateToUrl = useNavigateToUrl();
  const { mobileBandwidthSavings } = useApplicationSettings();
  const [ref, loaded] = useLazyImage<HTMLDivElement>(cover);
  const useCover = !mobileBandwidthSavings && loaded;
  const classes = useStyles({
    cover: useCover ? cover : undefined,
    noOpacity,
    customHeight,
    noClickable: false,
  });

  const profilePictureUrl = useMemo(
    () => getImageWithFullPath(admin.profilePictureUrl),
    [admin.profilePictureUrl]
  );

  const handleClick = async (props) => {
    // because we are showing a warning message if user has no free slot and onClick function triggers this function
    if (freeAvailability === 0) {
      return onClick(props);
    }
    if (
      (checkIfPostMustBeHidden && (await shouldGoPost(sharingPostId))) ||
      !checkIfPostMustBeHidden
    ) {
      return onClick(props);
    }
    if (tag === 'custom') return navigateToUrl(`/search?q=${title}`);
    return navigateToUrl(`/search?tags=${tag}`);
  };

  return (
    <Paper
      ref={ref}
      id={id}
      role='link'
      data-href={postUrl}
      data-kind='sharing-card'
      className={clsx(classes.root, {
        [classes.zeroFreeAvailability]: !freeAvailability,
      })}
      elevation={0}
    >
      <Container className={classes.avatarContainer}>
        <AdminAvatar
          fullName={admin.fullName}
          profileUrl={admin.profileUrl}
          profilePictureUrl={profilePictureUrl}
          verified={admin.verified}
          trust={admin.trust}
          onClick={onClickAdmin}
        />
      </Container>
      <Paper
        component={Container}
        className={clsx(classes.header, {
          [classes.withCover]: useCover,
        })}
        elevation={0}
        onClick={handleClick}
      >
        {!freeAvailability && (
          <Grid className={classes.full}>
            <Typography style={{ fontSize: '12px' }}>
              <Trans i18nKey='PostCard.Label.full'>Full</Trans>
            </Typography>
          </Grid>
        )}
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.ratingsRow} />
            <Grid item xs={12} className={classes.adminFullNameRow}>
              <AdminFullName
                fullName={admin.fullName}
                ratings={admin.ratings}
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} />
            <Grid item xs={12} className={classes.postTitleRow}>
              <SharingPostTitle
                title={title}
                secretStatus={secretStatus}
                classes={{
                  title: clsx(classes.title, classes.primaryDark),
                  secretKey: classes.secretKey,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.joinersRow}>
              <JoinersAvatarGroup max={3}>
                {joiners.map((joiner) => (
                  <LazyAvatar
                    key={joiner.fullName}
                    alt={joiner.fullName}
                    src={getImageWithFullPath(joiner.profilePictureUrl)}
                    imgProps={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      loading: 'lazy',
                      decoding: 'sync',
                    }}
                  />
                ))}
              </JoinersAvatarGroup>
            </Grid>
            {children}
            <Grid item xs={12} container justify='space-between'>
              <Grid item xs>
                <Typography
                  variant='overline'
                  display='block'
                  align='center'
                  color='primary'
                  className={classes.infoTitle}
                >
                  <SharingCardFrequency
                    variant={frequency}
                    discounted={discounted}
                  />
                </Typography>
                <Typography
                  component='div'
                  variant='h6'
                  align='center'
                  color={discounted ? 'secondary' : 'primary'}
                  className={clsx({
                    [classes.primaryDark]: !discounted,
                    [classes.price]: true,
                  })}
                >
                  {Money.toString(price)}
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation='vertical' className={classes.divider} />
              </Grid>
              <Grid item xs>
                <Typography
                  variant='overline'
                  display='block'
                  align='center'
                  color='primary'
                  className={classes.infoTitle}
                >
                  <Trans i18nKey='SharingCard.free-availability'>
                    POSTI LIBERI
                  </Trans>
                </Typography>
                <Typography
                  component='div'
                  variant='h6'
                  align='center'
                  color={!freeAvailability ? 'secondary' : 'primary'}
                  className={clsx({
                    [classes.primaryDark]: !!freeAvailability,
                    [classes.price]: true,
                  })}
                >
                  {freeAvailability}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Container>
      </Paper>
    </Paper>
  );
};

export default SharingCard;
