import { useMemo } from 'react';

const useFilteredList = <T>(
  filters: Array<T>,
  aceeptedList: Array<T>,
  defaultValue: Array<T> = []
): Array<T> => {
  const filteredFilters = useMemo(() => {
    const filtered = filters.filter((item) => aceeptedList.includes(item));

    if (!filtered || filtered.length === 0) return defaultValue;
    return filtered;
  }, [filters, aceeptedList, defaultValue]);

  return filteredFilters;
};

export default useFilteredList;
