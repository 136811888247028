import MagicBook from "@dev-togetherprice/icons.magic-book";
import {
  alpha,
  Button,
  Grid,
  makeStyles,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    backgroundColor: alpha(theme.palette.primary.main, 0.35),
  },
  drawer: {
    borderRadius: theme.spacing(5, 5, 0, 0),
    padding: theme.spacing(3, 6, 10, 6),
  },
  image: {
    fontSize: 96,
    margin: theme.spacing(9, 0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  body: {
    marginBottom: theme.spacing(12),
  },
  button: {
    borderRadius: theme.spacing(2),
    margin: theme.spacing(2, 0),
    height: 55,
    textTransform: "none",
    boxShadow: "none",
    transition: ".4s",
  },
  input: {
    marginTop: theme.spacing(3),
  },
}));

export type CreateYourOwnGroupModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
};

const CreateYourOwnGroupModal: FC<CreateYourOwnGroupModalProps> = ({
  open,
  setOpen,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawer,
      }}
    >
      <Grid item xs={12} container direction="column" alignItems="center">
        <MagicBook className={classes.image} />
        <Typography variant="h1" color="primary" className={classes.title}>
          <Trans i18nKey="SearchControls.CreateYourOwnGroupModal.title" />
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.body}
        >
          <Trans i18nKey="SearchControls.CreateYourOwnGroupModal.description" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onConfirm}
          fullWidth
        >
          <Trans i18nKey="SearchControls.CreateYourOwnGroupModal.button" />
        </Button>
      </Grid>
    </SwipeableDrawer>
  );
};

export default CreateYourOwnGroupModal;
