import { AtomEffect } from 'recoil';

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never;
type AtomEffectArguments = ArgumentTypes<AtomEffect<any>>[0];

export type HandleUrlParamsType = (
  urlKey: string,
  atomEffect: AtomEffectArguments
) => void;

const handleUrlParams: HandleUrlParamsType = (urlKey, { onSet, setSelf }) => {
  onSet((newVal) => {
    const url = new URL(window.location as any);
    if (newVal) {
      url.searchParams.set(urlKey, newVal);
      window.history.replaceState(null, '', url.toString());
    }
  });

  setSelf((defaultValue) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ty = typeof defaultValue;
    let urlValue;
    const str = urlSearchParams.get(urlKey);
    switch (ty) {
      case 'string':
        urlValue = str;
        break;
      case 'number':
      case 'bigint':
        urlValue = +str;
        break;
      case 'boolean':
        urlValue = str;
        break;
      case 'object':
        if (str) urlValue = str.split(',');
        break;
      default:
        urlValue = str;
    }

    return urlValue || defaultValue;
  });
};

export default handleUrlParams;
