import { Grid } from '@material-ui/core';
import React, {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

export const useOnIntersect = <E extends Element>(
  ref: MutableRefObject<E>,
  onChange: (intersection: boolean) => void | Promise<any>,
  init?: IntersectionObserverInit
) => {
  const observer = useMemo(() => {
    let running = false;
    return new IntersectionObserver(([entry]) => {
      if (running) return;
      running = true;
      Promise.resolve(onChange(entry.isIntersecting)).finally(() => {
        running = false;
      });
    }, init);
  }, [onChange, init]);
  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);
};

export type OnIntersectProps = {
  onChange: (intersecting: boolean) => void;
  children?: ReactNode;
  options?: IntersectionOptions
};
const OnIntersect: React.FC<OnIntersectProps> = ({ onChange, children, options }) => {
  const { ref, inView } = useInView(options);

  useEffect(() => {
    onChange(inView);
  }, [inView, onChange]);

  return <Grid container spacing={2} ref={ref}>{children}</Grid>;
};

export default OnIntersect;
