import { withStyles } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const JoinersAvatarGroup = withStyles(
  (theme) => ({
    root: {
      margin: 'auto',
      width: 'fit-content',
    },
    avatar: {
      width: '32px',
      height: '32px',
      backgroundColor: theme.palette.common.white,
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
  }),
  { name: 'JoinersAvatarGroup' }
)(AvatarGroup);

export default JoinersAvatarGroup as typeof AvatarGroup;
