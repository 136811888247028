import { useAvailablePostSearch } from "@/api/shortFunnel";
import { useOpenAdminInfoPanel } from "@/components/AdminInfoPanel/AdminInfoPanel";
import CreateYourOwnGroupModal from "@/components/CreateYourOwnGroupModal/CreateYourOwnGroupModal";
import SearchStateObserver from "@/components/SearchStateObserver";
import { SearchTab } from "@/components/SearchTabs";
import { useSetSharingPreviewPanel } from "@/components/SharingPreviewPanel/SharingPreviewPanel";
import {
  DEFAULT_PUBLIC_SEARCH_API_URL,
  DEFAULT_SEARCH_API_URL,
  GROUPS_FILTERS,
} from "@/constants/search";
import useSearchTitle from "@/hooks/search/useSearchTitle";
import useSearchInput from "@/hooks/useSearchInput";
import { autoFocusedSearchState } from "@/recoil/atoms/app";
import {
  apiState,
  categoriesState,
  filtersState,
  queryState,
  serviceIdState,
  tagsState
} from "@/recoil/atoms/search";
import { useNavigateToUrl } from "@dev-togetherprice/components.app-context";
import PageWrapper from "@dev-togetherprice/components.page-wrapper";
import { Box, CircularProgress, Grid, Hidden, makeStyles } from "@material-ui/core";
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import ShortFunnelSearchTabs from "../ShortFunnelSearchTabs";
import List from "@/components/List";
import SearchHeader from "../../Search/SearchHeader";
import SearchResultPlaceHolder from "@/components/SearchResultPlaceHolder";
import ResponsiveSharingCard from "@/components/ResponsiveSharingCard";
import useFilteredList from "@/hooks/useFilteredList";
import useSearchResultQuery from "@/hooks/search/useSearchResultQuery";
import { updateOnboarding } from "@/api/on-boarding";

const TopPublicBar = lazy(() => import("@/components/TopPublicBar"));
const SearchFirstResults = lazy(
  () =>
    import(
      "@/App/Routes/ShortFunnel/ShortFunnelFirstResults/ShortFunnelFirstResults"
    )
);

const FullSpinner = () => {
  return (
    <Box
      flex={1}
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {},
  mobileInner: {
    padding: theme.spacing(1, 0),
  },
  list: {
    padding: theme.spacing(0, 4),
  }
}));

export type SearchProps = {
  usePublicNetwork?: boolean;
  marketCountry?: string;
};
const ShortFunnelSearch: React.FC<SearchProps> = ({
  usePublicNetwork = false,
  marketCountry: defaultMarketCountry,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const navigateToUrl = useNavigateToUrl();
  const openAdminInfoPanel = useOpenAdminInfoPanel();

  const [
    CreateYourOwnGroupModalOpen,
    SetCreateYourOwnGroupModalOpen,
  ] = useState(false);

  const [title] = useSearchTitle(defaultMarketCountry, usePublicNetwork);

  const serviceId = useRecoilValue(serviceIdState);
  useAvailablePostSearch({
    selectedServiceId: serviceId,
    pageNumber: 0,
  });
  const lastSlashIndex = location.pathname.lastIndexOf("/");
  const serviceName = location.pathname.substring(lastSlashIndex + 1);
  const groupKey = location.pathname.split("/")[1];

  useEffect(() => {
    SetInitialQuery("");
  }, []);

  const sharingPreviewPanel = useSetSharingPreviewPanel();

  const { defaultValue, handleSearchChange } = useSearchInput();
  const [isSearchStarted, SetIsSearchStarted] = useState(false);
  const [initialQuery, SetInitialQuery] = useRecoilState(queryState);

  const tags = useRecoilValue(tagsState);
  const categories = useRecoilValue(categoriesState);
  const searchApiUrl = useRecoilValue(apiState);
  const isFocused = useRecoilValue(autoFocusedSearchState);

  useEffect(() => {
    if (initialQuery !== "") {
      SetIsSearchStarted(true);
    }
  }, [initialQuery]);

  const onBack = useCallback(() => {
    if (history.length > 0) {
      return history.goBack();
    }
    return history.push("/");
  }, [history]);

  const filters = useRecoilValue(filtersState);

  const filteredFilters = useFilteredList<string>(filters, GROUPS_FILTERS, []);

  const query = useSearchResultQuery({ filters: filteredFilters });

  const renderSearchTypeComponent = () => {
    return isSearchStarted ? (
       <Grid container>
        <List
          classes={{ root: classes.list }}
          header={<SearchHeader marketCountry={defaultMarketCountry} />}
          query={query}
          size={16}
          component={ResponsiveSharingCard}
          defaultObjectName="post"
          onClickPost={onClickPost}
          onClickPostAdmin={onClickPostAdmin}
          noOpacity
          onResultFound={(isVerified) => {
            if (isVerified && groupKey !== "green_key_groups") navigateToUrl(`/green_key_groups/${serviceName}${location.search}`)
            if (!isVerified && groupKey !== "gray_key_groups") navigateToUrl(`/gray_key_groups/${serviceName}${location.search}`)
            }
          }
          infinite
          noDataComponent={<SearchResultPlaceHolder />}
        />
      </Grid>
    ) : (
      <SearchFirstResults
        marketCountry={defaultMarketCountry}
        serviceId={serviceId}
        onClickPost={onClickPost}
        onClickPostAdmin={onClickPostAdmin}
        onResultFound={() => SetCreateYourOwnGroupModalOpen(false)}
        onResultNotFound={() => SetCreateYourOwnGroupModalOpen(true)}
      />
    );
  };
  const showSearch = useMemo(
    () =>
      !(
        !!tags.length ||
        !!categories.length ||
        (searchApiUrl !== DEFAULT_SEARCH_API_URL &&
          searchApiUrl !== DEFAULT_PUBLIC_SEARCH_API_URL)
      ),
    [tags, categories, searchApiUrl]
  );

  const onClickPost = useCallback(
    (post) => {
      if (usePublicNetwork) {
        sharingPreviewPanel(post);
        return;
      }
      if (post.secretStatus === "VALID") {
        return navigateToUrl(
          `/green_key_checkout/${serviceName}/post/${post.sharingPostId}`
        );
      }
      return navigateToUrl(
        `/gray_key_checkout/${serviceName}/post/${post.sharingPostId}`
      );
    },
    [navigateToUrl, sharingPreviewPanel, usePublicNetwork]
  );

  const onClickPostAdmin = useCallback(
    (post) => {
      const { admin, sharingPostId } = post;
      if (usePublicNetwork) {
        sharingPreviewPanel(post);
        return;
      }

      openAdminInfoPanel({
        userId: admin.userId,
        sharingPostId,
        trust: admin.trust,
        lastAccess: admin.lastSession,
        avgResponseTime: admin.adminAvgTime,
      });
    },
    [openAdminInfoPanel, sharingPreviewPanel, usePublicNetwork]
  );

  const fullTopAppBarProps = useMemo(
    () => ({
      mobileProps: {
        title,
        classes: {
          title: classes.title,
        },
        onBack,
        showSearch,
      },
      searchProps: {
        defaultValue,
        autoFocus: isFocused,
        onChange: handleSearchChange,
      },
    }),
    [
      classes.title,
      defaultValue,
      handleSearchChange,
      onBack,
      showSearch,
      title,
      isFocused,
    ]
  );

  const pageClasses = useMemo(
    () => ({
      mobileInner: classes.mobileInner,
    }),
    [classes.mobileInner]
  );

  return (
    <>
      {usePublicNetwork && (
        <Suspense fallback={null}>
          <TopPublicBar />
        </Suspense>
      )}
      <PageWrapper
        isPublic={usePublicNetwork}
        activeTab="groups"
        navigateToUrl={
          async (path) => {
            await updateOnboarding({column: "completed"}) 
            navigateToUrl(path)
          }
        }
        FullTopAppBarProps={fullTopAppBarProps}
        classes={pageClasses}
      >
        <SearchStateObserver />
        <Switch>
          <Route
            path={[
              "/gray_key_groups/:service",
              "/green_key_groups/:service",
              "/unavailable_group/:service",
            ]}
            exact
          >
            <Suspense fallback={<FullSpinner />}>
              {renderSearchTypeComponent()}
            </Suspense>
          </Route>
        </Switch>
        <CreateYourOwnGroupModal
          open={CreateYourOwnGroupModalOpen}
          setOpen={() => SetCreateYourOwnGroupModalOpen(false)}
          onConfirm={() => navigateToUrl(`/create`)}
        />
      </PageWrapper>
    </>
  );
};

export default ShortFunnelSearch;
