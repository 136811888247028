import { Preferences } from '@capacitor/preferences';
import * as appStates from '@/recoil/atoms/app';
import { MutableSnapshot } from 'recoil';

const initAppState = async (snapshot: MutableSnapshot) => {
  const data = await Preferences.get({ key: 'mf-network.app_state' });

  if (!data || !data.value) return;

  try {
    const parsedData = JSON.parse(data.value) as Record<string, any>;

    Object.entries(parsedData).map(([key, value]) => {
      const state = Object.entries(appStates).find(
        ([_, stateValue]) => key === stateValue.key
      );

      if (!state || !state[1]) return false;

      return snapshot.set(state[1], value);
    });
  } catch (err) {
    console.error(err);
  }
};

export default initAppState;
