import { AdvertisingBannerResponse } from '@/api/promo';
import { Plan, SecretType, Service } from '@/api/service';
import { UserInfo } from '@bit/together-price.core.api.user';
import { atom } from 'recoil';

export const hasPhoneState = atom({
  key: 'hasPhoneState',
  default: false,
});

export const phoneState = atom({
  key: 'phoneState',
  default: '',
});

export const phonePrefixState = atom({
  key: 'phonePrefixState',
  default: 39,
});

export const titleState = atom({ key: 'titleState', default: 'Create.group' });

export const noBackState = atom({ key: 'noBackState', default: false });

export const servicesState = atom<Array<Service>>({
  key: 'servicesState',
  default: [],
});

export const routeState = atom({ key: 'routeState', default: '' });

export const tpasswordState = atom<SecretType>({
  key: 'tpasswordState',
  default: null,
});

export const plansState = atom<Plan[]>({ key: 'plansState', default: [] });

export const currentPlanState = atom<Plan>({ key: 'planState', default: null });

export const visibilityState = atom({
  key: 'visibilityState',
  default: 'public',
});

export const slotState = atom({
  key: 'slotState',
  default: 1,
});

export const hasPriceState = atom({
  key: 'hasPriceState',
  default: false,
});

export const userInfoState = atom<UserInfo>({
  key: 'userInfoState',
  default: null,
});

export const stepState = atom({ key: 'stepState', default: 0 });

export const showMessageState = atom({
  key: 'showMessageState',
  default: false,
});

export const durationState = atom({ key: 'durationState', default: '' });

export const whatsappState = atom({ key: 'whatsappState', default: false });

export const groupState = atom({ key: 'groupState', default: null });

export const errorState = atom({ key: 'errorState', default: null });

export const imageState = atom({ key: 'imageState', default: undefined });

export const joinState = atom({ key: 'joinState', default: 'auto' });

export const priceState = atom({ key: 'priceState', default: null });

export const promoState = atom<AdvertisingBannerResponse>({
  key: 'promoState',
  default: null,
});
