import React, { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Trans } from 'react-i18next';
import useRegisteredTranslation from '@bit/together-price.core.hooks.use-register-translation';
import noresult from './assets/noresult.svg';

import en from './i18n/en.json';
import es from './i18n/es.json';
import it from './i18n/it.json';
import pt from './i18n/pt.json';

const useStyles = makeStyles((theme) => ({
  body: {
    fontSize: 14,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 4),
  },
}));
const SearchResultPlaceHolder: React.FC = () => {
  const classes = useStyles();
  const { t } = useRegisteredTranslation('SearchResultPlaceHolder', {
    en,
    es,
    it,
    pt,
  });

  const components = useMemo(
    () => ({
      1: <Typography variant='body1' component='strong' color='primary' />,
    }),
    []
  );

  return (
    <Box
      m='auto'
      width='fit-content'
      pt={10}
      pb={10}
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <img
        alt='no search results'
        src={noresult}
        style={{ maxWidth: '200px', width: '100%' }}
      />
      <Box mt={10}>
        <Box mb={4}>
          <Typography
            className={classes.body}
            variant='h6'
            color='primary'
            align='center'
          >
            <Trans t={t} i18nKey='noResult' />
          </Typography>
          <Typography
            className={classes.body}
            variant='body1'
            color='textPrimary'
            align='center'
          >
            <Trans t={t} i18nKey='tryAgain' />
          </Typography>
        </Box>
        <Typography
          className={classes.body}
          variant='body1'
          color='textPrimary'
        >
          <Trans t={t} i18nKey='searchGroup' components={components} />
        </Typography>
        <Typography
          className={classes.body}
          variant='body1'
          color='textPrimary'
        >
          <Trans t={t} i18nKey='searchPerson' components={components} />
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchResultPlaceHolder;
