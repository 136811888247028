import React, { useState } from 'react';
import { Post } from '@/api/search';
import {
  Button,
  ButtonProps,
  createStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import IconButton from '@bit/together-price.core.components.icon-button';
import CategoryIcon from '@/components/CategoryIcon';
import Container from '@material-ui/core/Container';
import JoinersAvatarGroup from '@/components/JoinersAvatarGroup';
import { Money, MoneyLike } from '@bit/together-price.core.monetary';
import { Trans } from 'react-i18next';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LazyAvatar from '@bit/together-price.core.components.lazy-avatar';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import ChatHelp from '@/icons/ChatHelp';
import useSharingPreviewStep from '@/components/SharingPreviewPanel/useSharingPreviewStep';
import { goToFaq } from '@/api/faq';
import useSharingPreviewPanelStyles from './useSharingPreviewPanelStyles';
import { useSharingPreviewPanelState } from './SharingPreviewPanel';

type SummaryRowProps = {
  label: React.ReactNode;
  value: React.ReactNode;
};
const SummaryRow = (props: SummaryRowProps) => {
  const { label, value } = props;
  return (
    <Grid container justify='space-between' alignItems='center'>
      <Grid item>{label}</Grid>
      <Grid item>{value}</Grid>
    </Grid>
  );
};

type PostPriceProps = {
  price: MoneyLike;
  frequency: Post['frequency'];
  title: string;
};
const PostPrice = (props: PostPriceProps) => {
  const classes = useSharingPreviewPanelStyles();
  const { price, frequency, title } = props;
  const [details, setDetails] = useState(false);
  const total = (
    <SummaryRow
      label={
        <Typography
          variant='body1'
          color='primary'
          className={classes.frequency}
        >
          <Trans
            i18nKey='SharingPreviewPanel.frequency'
            tOptions={{
              context: frequency,
            }}
          >
            Quota mensile:
          </Trans>
        </Typography>
      }
      value={
        <Button
          variant='text'
          color='primary'
          className={classes.price}
          endIcon={!details && <InfoIcon className={classes.priceInfoIcon} />}
          onClick={() => setDetails(true)}
        >
          {Money.toString(price)}
        </Button>
      }
    />
  );
  return (
    <Container
      className={clsx(classes.priceContainer, {
        [classes.priceContainerColored]: details,
      })}
    >
      {details && (
        <>
          <Grid container justify='space-between'>
            <Grid item xs>
              <Typography
                variant='body2'
                color='primary'
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                <Trans
                  i18nKey='SharingPreviewPanel.priceDetails'
                  tOptions={{
                    context: frequency,
                  }}
                  values={{ price: Money.toString(price), title }}
                />
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setDetails(false)}
                style={{
                  padding: 4,
                  paddingLeft: 16,
                }}
              >
                <svg
                  width='17'
                  height='17'
                  viewBox='0 0 17 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M1 1L15.7857 16' stroke='#444444' />
                  <path d='M16 1L1.21428 16' stroke='#444444' />
                </svg>
              </IconButton>
            </Grid>
          </Grid>
          <br />
          <Typography
            variant='body2'
            color='primary'
            style={{
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            <Trans i18nKey='SharingPreviewPanel.priceDetailsMessage'>
              Non sei vincolato, puoi uscire dal gruppo di condivisione in
              qualunque momento.
            </Trans>
          </Typography>
        </>
      )}
      {!details && total}
    </Container>
  );
};

type ActionButtonProps = ButtonProps;
const defaultActionButtonProps: ButtonProps = {
  endIcon: <ChevronRightIcon color='primary' style={{ fontSize: 30 }} />,
  fullWidth: true,
  variant: 'text',
};
const ActionButton = withStyles(
  (theme) =>
    createStyles({
      root: {
        fontSize: 16,
        lineHeight: '24px',
        height: '70px',
        textTransform: 'none',
        textAlign: 'left',
        fontWeight: 'normal',
        justifyContent: 'space-between',
        padding: theme.spacing(8, 7),
        '&:not(:last-child)': {
          borderBottom: '1px solid rgba(221, 210, 255, 0.83)',
        },
      },
      text: {
        color: '#585858',
      },
    }),
  { name: 'ActionButton' }
)((props: ActionButtonProps) => (
  <Button {...defaultActionButtonProps} {...props} />
));

const HelpButton = withStyles(
  (theme) =>
    createStyles({
      root: {
        fontSize: 16,
        lineHeight: '24px',
        height: '70px',
        textTransform: 'none',
        textAlign: 'left',
        fontWeight: 'normal',
        padding: theme.spacing(8, 7),
      },
    }),
  { name: 'HelpButton' }
)((props: ActionButtonProps) => (
  <Button
    startIcon={<ChatHelp color='primary' />}
    variant='text'
    color='primary'
    fullWidth
    {...props}
  />
));

const PostPreview: React.FC = () => {
  const classes = useSharingPreviewPanelStyles();
  const [, setStep] = useSharingPreviewStep();
  const navigateToUrl = useNavigateToUrl();
  const { value: post } = useSharingPreviewPanelState();
  const {
    sharingPostId,
    postTitle,
    category,
    admin,
    joiners,
    monthlyPrice,
    frequency,
  } = post;
  const serviceName = postTitle;
  return (
    <>
      <Container className={classes.header}>
        <Grid container spacing={5} alignItems='center'>
          <Grid item>
            <Container className={classes.categoryContainer}>
              <CategoryIcon
                className={classes.categoryIcon}
                variant={category}
                color='primary'
                fontSize='large'
              />
            </Container>
          </Grid>
          <Grid item xs>
            <Typography variant='h1' component='div' className={classes.title}>
              <Trans i18nKey='SharingPreviewPanel.caption'>
                Condividi il costo di
              </Trans>
            </Typography>
            <Typography
              variant='h1'
              component='h2'
              className={classes.titleStrong}
            >
              {postTitle}
            </Typography>
          </Grid>
          <Grid item>
            <JoinersAvatarGroup
              max={3}
              onClick={() => setStep('admin', 'replaceIn')}
            >
              <LazyAvatar src={admin.imagePath} />
              {joiners.map((joiner) => (
                <LazyAvatar key={joiner.userId} src={joiner.imagePath} />
              ))}
            </JoinersAvatarGroup>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PostPrice
              title={postTitle}
              price={monthlyPrice}
              frequency={frequency}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              size='large'
              onClick={() =>
                navigateToUrl(`/post/${sharingPostId}/fast-checkout`)
              }
              disableElevation
              className={classes.button}
            >
              <Trans i18nKey='SharingPreviewPanel.join'>Partecipa</Trans>
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.actions}>
        <ActionButton onClick={() => setStep('how', 'replaceIn')}>
          <Trans i18nKey='SharingPreviewPanel.action-how'>
            Come funziona la condivisione
          </Trans>
        </ActionButton>
        <ActionButton onClick={() => setStep('info', 'replaceIn')}>
          <Trans
            i18nKey='SharingPreviewPanel.action-info'
            values={{ serviceName }}
          />
        </ActionButton>
        <ActionButton onClick={() => setStep('admin', 'replaceIn')}>
          <Trans i18nKey='SharingPreviewPanel.action-admin'>
            Persone con le quali condividerai
          </Trans>
        </ActionButton>
        <HelpButton onClick={() => goToFaq(post.marketCountry)}>
          <Trans i18nKey='SharingPreviewPanel.other-questions'>
            Altre domande?
          </Trans>
        </HelpButton>
      </Container>
    </>
  );
};

export default PostPreview;
