import React from 'react';
import { useTranslation } from 'react-i18next';

export const useGetServiceName = (): ((value: string) => string) => {
  const { t } = useTranslation();
  return (value) => {
    if (value === 'Custom') {
      return t('ServiceName.custom');
    }
    return value;
  };
};

export type ServiceNameProps = {
  value: string;
};
const ServiceName: React.FC<ServiceNameProps> = ({ value }) => {
  const getServiceName = useGetServiceName();
  return <>{getServiceName(value)}</>;
};

export default ServiceName;
