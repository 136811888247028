import { Post, generateGetKey } from "@/api/search";
import SearchCardSkeleton from "@/components/SearchGroupsResult/SearchCardSkeleton";
import { OverridableComponent } from "@/types/OverridableComponent";
import { Fetcher } from "@bit/together-price.core.api.fetch";
import { fetchNetwork } from "@bit/together-price.core.api.services";
import { Grid, makeStyles } from "@material-ui/core";
import { mergeClasses } from "@material-ui/styles";
import React, {
  ComponentProps,
  ComponentType,
  FC,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWRInfinite from "swr/infinite";
import OnIntersect from "../OnIntersect";
import ResponsiveSharingCard from "../ResponsiveSharingCard";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
  header: {
    fontSize: "18px",
    fontWeight: 500,
    margin: theme.spacing(2, 0),
  },
  list: {
    padding: theme.spacing(2, 0),
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      justifyItems: "center",
      gridTemplateColumns:
        "repeat(auto-fill, [col-start] minmax(260px, 1fr) [col-end])", // this function will be enough for all sizes
      columnGap: "10px",
      rowGap: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export type ListClassKey = "root" | "header" | "list";

export interface ListBaseProps {
  query?: string;
  defaultData?: any[];
  size?: number;
  header?: String | ReactNode;
  endComponent?: ReactNode;
  noDataComponent?: ReactNode;
  infinite?: boolean;
  fetcher?: Fetcher<any>;
  component?: ComponentType<any> | undefined;
  placeholder?: ReactNode;
  restructureData?: (data: any) => Promise<any[]> | any[];
  onResultFound?: (isVerified: boolean) => void;
  onResultNotFound?: () => void;
  defaultObjectName?: string;
}

/** TODO(suley): Instead of using component prop,
 * use renderItem function param like FlatList component of react-native
 * */
export interface ListTypeMap<
  P = {},
  C extends React.ElementType = typeof ResponsiveSharingCard,
  D extends string = "post"
> {
  props: Partial<P> & ListBaseProps;
  defaultComponent: C;
  defaultObjectName: D;
  classKey: ListClassKey;
}

export type ListType = OverridableComponent<ListTypeMap>;

export type ListProps = ComponentProps<ListType>;

const List: FC<ListProps> = forwardRef(
  ({
    query,
    size = 16,
    header,
    defaultObjectName = "post",
    placeholder = <SearchCardSkeleton />,
    component: CardComponent = ResponsiveSharingCard,
    noDataComponent,
    classes = {},
    infinite = false,
    restructureData,
    onResultFound,
    onResultNotFound,
    endComponent,
    defaultData,
    fetcher = fetchNetwork,
    ...cardProps
  }) => {
    const [posts, setPosts] = useState<Post[]>(undefined);
    const baseClasses = useStyles();
    const allClasses = mergeClasses({
      baseClasses,
      newClasses: classes,
      Component: List,
    });

    const { data, setSize } = useSWRInfinite<Post[]>(
      query && generateGetKey(query, size),
      fetcher,
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
        suspense: false,
        fallbackData: defaultData,
        dedupingInterval: 8000,
        revalidateAll: false,
      }
    );

    const hasNoData = useMemo(() => posts && posts.length === 0, [posts]);

    const lastResultSize = useMemo(() => data?.[data.length - 1]?.length, [
      data,
    ]);

    const hasMore = useMemo(() => lastResultSize === size, [
      lastResultSize,
      size,
    ]);

    const isLoading = useMemo(() => !data, [data]);

    const intersectOptions = useMemo(() => ({ rootMargin: "100px 20px" }), []);

    const shouldIntersect = useMemo(() => !isLoading && hasMore && infinite, [
      isLoading,
      hasMore,
      infinite,
    ]);

    const isEnd = useMemo(() => !hasMore && !isLoading, [hasMore, isLoading]);

    const nextPage = useCallback(
      (intersecting: boolean) => {
        if (intersecting) {
          return setSize((current) => current + 1);
        }
        return null;
      },
      [setSize]
    );

    useEffect(() => {
      const flattedData = data?.flat();
      Promise.resolve(restructureData?.(flattedData))
        .then((newPosts) => {
          setPosts(newPosts || flattedData);
        })
        .catch((error) => {
          console.error(error);
        });
      if (flattedData) {
        if (flattedData.length > 0) {
          return onResultFound?.(flattedData[0].secretStatus === "VALID");
        }

        return onResultNotFound?.();
      }
    }, [restructureData, data]);

    if (hasNoData && !noDataComponent && !endComponent) return null;

    return (
      <Grid container direction="column" className={allClasses.root}>
        {header && (
          <Grid
            className={allClasses.header}
            container
            justify="center"
            alignItems="center"
          >
            {header}
          </Grid>
        )}
        {hasNoData ? (
          noDataComponent
        ) : (
          <Grid container className={allClasses.list}>
            {isLoading && (
              <>
                {placeholder}
                {placeholder}
                {placeholder}
              </>
            )}
            {posts?.map((post: Post, index) => (
              <CardComponent
                key={`CardComponent-${index}`}
                {...{ [defaultObjectName]: post }}
                {...cardProps}
              />
            ))}
            {shouldIntersect && (
              <OnIntersect onChange={nextPage} options={intersectOptions}>
                {placeholder}
              </OnIntersect>
            )}
          </Grid>
        )}
        {/**
         * to be sure that posts are loaded and there is no more post
         */}
        {isEnd && endComponent}
      </Grid>
    );
  }
);

export default List as ListType;
