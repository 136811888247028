import React, { useCallback, useEffect } from 'react';
import { Post } from '@/api/search';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useQueryParam, NumberParam } from 'use-query-params';
import PostPreview from '@/components/SharingPreviewPanel/PostPreview';
import AdminPreview from '@/components/SharingPreviewPanel/AdminPreview';
import useSharingPreviewPanelStyles from '@/components/SharingPreviewPanel/useSharingPreviewPanelStyles';
import useSharingPreviewStep from '@/components/SharingPreviewPanel/useSharingPreviewStep';
import HowWork from '@/components/SharingPreviewPanel/HowWork';
import InfoService from '@/components/SharingPreviewPanel/InfoService';
import clsx from 'clsx';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useLocationState, {
  useSetLocationStateAction,
} from '@bit/together-price.core.hooks.use-location-state';

const key = 'SharingPreviewPanel';

export const useSetSharingPreviewPanel = (): ((data: Post) => void) => {
  const setSharingPanel = useSetLocationStateAction<Post>(key, null);

  const openSharingPreviewPanel = useCallback(
    (data: Post) => setSharingPanel(data),
    [setSharingPanel]
  );

  return openSharingPreviewPanel;
};

export const useSharingPreviewPanelState = () => useLocationState(key, null);

const SharingPreviewPanel: React.FC = () => {
  const classes = useSharingPreviewPanelStyles();
  const {
    value: state,
    setLocationState: setState,
  } = useSharingPreviewPanelState();
  const [, setPreview] = useQueryParam('preview', NumberParam);
  const [step, setStep] = useSharingPreviewStep();
  const sharingPostId = state?.sharingPostId;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setPreview(sharingPostId, 'replaceIn');
    if (!sharingPostId) {
      setStep(undefined, 'replaceIn');
    }
  }, [sharingPostId, setPreview, setStep]);
  if (!state) return <></>;
  return (
    <SwipeableDrawer
      keepMounted
      disableSwipeToOpen
      open={!!state}
      onClose={() => setState(null)}
      onOpen={() => {}}
      anchor={matches ? 'right' : 'bottom'}
      classes={{
        paper: clsx(classes.root, {
          [classes.stepHow]: step === 'how',
          [classes.stepInfo]: step === 'info',
        }),
      }}
    >
      {!step && <PostPreview />}
      {step === 'admin' && <AdminPreview />}
      {step === 'how' && <HowWork />}
      {step === 'info' && <InfoService />}
    </SwipeableDrawer>
  );
};

export default SharingPreviewPanel;
