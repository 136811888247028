import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import IconButton from '@bit/together-price.core.components.icon-button';
import Container from '@material-ui/core/Container';
import BackIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { Trans } from 'react-i18next';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import clsx from 'clsx';
import AddIcon from '@bit/together-price.core.icons.add';
import VerifiedBadge from '@bit/together-price.core.components.verified-badge';
import LazyAvatar from '@bit/together-price.core.components.lazy-avatar';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import { differenceInMinutes } from 'date-fns';
import useSharingPreviewPanelStyles from './useSharingPreviewPanelStyles';
import useSharingPreviewStep from './useSharingPreviewStep';
import { useSharingPreviewPanelState } from './SharingPreviewPanel';

export type VerifiedIconProps = {
  status: 'success' | 'error';
};
const VerifiedIcon: React.FC<VerifiedIconProps> = ({ status }) => {
  const classes = useSharingPreviewPanelStyles();
  if (status === 'success')
    return <CheckCircleOutlineRoundedIcon className={clsx(classes.success)} />;
  if (status === 'error')
    return <HighlightOffRoundedIcon className={clsx(classes.error)} />;
  return <></>;
};

const AdminPreview: React.FC = () => {
  const classes = useSharingPreviewPanelStyles();
  const [, setStep] = useSharingPreviewStep();
  const navigateToUrl = useNavigateToUrl();
  const { value: post } = useSharingPreviewPanelState();
  const { sharingPostId, admin, joiners } = post;
  const lastAccessDelta = differenceInMinutes(
    new Date(),
    new Date(admin.lastSession)
  );

  return (
    <Container className={classes.content}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <IconButton
            color='primary'
            onClick={() => setStep(undefined, 'replaceIn')}
            style={{ padding: 0 }}
          >
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h1' color='primary'>
            <Trans i18nKey='SharingPreviewPanel.admin'>Admin</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignContent='center' alignItems='center' spacing={5}>
            <Grid item>
              <VerifiedBadge invisible={!admin.verified}>
                <LazyAvatar
                  src={admin.imagePath}
                  style={{ height: 80, width: 80 }}
                  classes={{
                    avatar: classes.adminInfoAvatar
                  }}
                />
              </VerifiedBadge>
            </Grid>
            <Grid item xs>
              <Typography
                variant='h2'
                style={{ fontSize: 16, lineHeight: '24px', fontWeight: 600 }}
              >
                {admin.adminFullname}
              </Typography>
              <Typography
                variant='body2'
                component='span'
                display='inline'
                style={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 'normal',
                }}
              >
                <Trans i18nKey='SharingPreviewPanel.admin-activity-last-access-title'>
                  Ultimo accesso:{' '}
                </Trans>
              </Typography>
              <Typography
                variant='body2'
                component='span'
                display='inline'
                color='primary'
                style={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 'normal',
                }}
              >
                <Trans
                  i18nKey='SharingPreviewPanel.admin-activity-last-access-value_interval'
                  tOptions={{
                    postProcess: 'interval',
                  }}
                  values={{
                    count: lastAccessDelta || 43231,
                  }}
                >
                  Oggi
                </Trans>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='body2'
            style={{ fontSize: 16, lineHeight: '24px' }}
          >
            <Trans i18nKey='SharingPreviewPanel.admin-info-trust'>
              Livello Affidabilità:{' '}
              <Typography variant='inherit' color='primary'>
                <>{{ trust: admin.trust }}%</>
              </Typography>
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            alignContent='center'
            className={classes.infoRow}
          >
            <Grid item xs>
              <Typography variant='inherit'>
                <Trans i18nKey='SharingPreviewPanel.admin-info-email'>
                  Indirizzo email verificato
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='inherit'>
                <VerifiedIcon
                  status={admin.emailVerified ? 'success' : 'error'}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            alignContent='center'
            className={classes.infoRow}
          >
            <Grid item xs>
              <Typography variant='inherit'>
                <Trans i18nKey='SharingPreviewPanel.admin-info-cellphone'>
                  Numero di telefono verificato
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='inherit'>
                <VerifiedIcon
                  status={admin.cellphoneVerified ? 'success' : 'error'}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            alignContent='center'
            className={classes.infoRow}
          >
            <Grid item xs>
              <Typography variant='inherit'>
                <Trans i18nKey='SharingPreviewPanel.admin-info-document'>
                  Documento di identità verificato
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='inherit'>
                <VerifiedIcon status={admin.verified ? 'success' : 'error'} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h1' component='h2' color='primary'>
            <Trans i18nKey='SharingPreviewPanel.joiners'>Partecipanti:</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            {joiners.map((joiner) => (
              <Grid item key={joiner.userId}>
                <LazyAvatar
                  src={joiner.imagePath}
                  alt={joiner.name}
                  style={{ width: 50, height: 50 }}
                  classes={{
                    avatar: classes.adminInfoAvatar
                  }}
                />
              </Grid>
            ))}
            <Grid item>
              <IconButton
                variant='filled'
                color='primary'
                shape='circle'
                style={{ width: 52, height: 52 }}
                onClick={() =>
                  navigateToUrl(`/post/${sharingPostId}/fast-checkout`)
                }
              >
                <AddIcon style={{ fontSize: 20 }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminPreview;
