import { useRecoilCallback } from 'recoil';
import * as appStates from '@/recoil/atoms/app';
import { Preferences } from '@capacitor/preferences';

async function processSnapshot(snapshot) {
  const allPersistedStates = await Promise.all(
    Object.values(appStates).map(async (val) => ({
      key: val.key,
      value: await snapshot.getPromise(val),
    }))
  );

  await Preferences.set({
    key: 'mf-network.app_state',
    value: JSON.stringify(
      allPersistedStates.reduce((a, v) => ({ ...a, [v.key]: v.value }), {})
    ),
  });
}

const useProcessSnapshot = () => {
  const saveState = useRecoilCallback(({ snapshot }) => () => {
    processSnapshot(snapshot);
  });

  return saveState;
};

export default useProcessSnapshot;
