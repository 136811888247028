import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import VerticalCentered from '@bit/together-price.core.components.vertical-centered';
import JoinersAvatarGroup from '../JoinersAvatarGroup/JoinersAvatarGroup';
import { useStyles } from './CompactSharingCard';

const CompactSharingCardSkeleton: React.FC = () => {
  const classes = useStyles({ noOpacity: false });
  return (
    <Container disableGutters className={classes.root}>
      <Container className={classes.header} disableGutters>
        <Grid container spacing={2}>
          <Grid item>
            <Container disableGutters className={classes.avatarContainer}>
              <Skeleton variant='circle' width='78px' height='78px' />
            </Container>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12} spacing={3}>
                <Typography
                  component='div'
                  variant='h1'
                  color='primary'
                  style={{
                    overflow: 'ellipses',
                    maxWidth: '200px',
                  }}
                  noWrap
                >
                  <Skeleton variant='text' />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant='text' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.footer}>
        <VerticalCentered>
          <Grid item xs={12} container justify='space-between' spacing={2}>
            <Grid item xs>
              <Typography component='div' variant='h1' align='center'>
                <Skeleton variant='text' />
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                component='div'
                variant='h1'
                align='center'
                color='primary'
              >
                <Skeleton variant='text' />
              </Typography>
            </Grid>
          </Grid>
        </VerticalCentered>
        <Box
          position='absolute'
          bottom='40px'
          left='50%'
          style={{
            transform: 'translateX(-50%)',
          }}
        >
          <JoinersAvatarGroup max={2}>
            <Skeleton variant='circle' height='32px' width='32px' />
            <Skeleton variant='circle' height='32px' width='32px' />
          </JoinersAvatarGroup>
        </Box>
      </Container>
    </Container>
  );
};

export default CompactSharingCardSkeleton;
