import React from 'react';
import { StyledComponentProps, Theme } from '@material-ui/core';
import { Styles, withStyles } from '@material-ui/styles';
import KeyIcon from '@bit/together-price.core.icons.key';
import clsx from 'clsx';
import { PostSecretStatus } from '../../api/search';

type SecretKeyClassKeys = 'root' | 'valid' | 'wrong' | 'unknown' | 'missing';
type SecretKeyProps = {
  className?: string;
  status?: PostSecretStatus;
} & StyledComponentProps<SecretKeyClassKeys>;

const SecretKey = withStyles<Styles<Theme, SecretKeyProps, SecretKeyClassKeys>>(
  () => ({
    root: { backgroundColor: 'transparent' },
    valid: { color: 'rgba(14,182,111,1)' },
    wrong: { color: '#FB4C68' },
    unknown: { color: '#969696' },
    missing: { color: '#969696' },
  }),
  { name: 'SecretKeyProps' }
)(({ className, classes, status = 'UNKNOWN', ...props }: SecretKeyProps) => (
  <KeyIcon
    className={clsx(className, classes.root, classes[status?.toLowerCase()])}
    {...props}
  />
));

export default SecretKey;
