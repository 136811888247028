import { Service, useServices } from '@/api/service';

const useSearchServices = (marketCountry: string): Service[] => {
  const services = useServices(
    marketCountry
      ? `/carousel/publicGetServices?marketCountry=${marketCountry}`
      : '/carousel/getServices'
  );

  return services;
};

export default useSearchServices;
