import { fetchAdvertisingBanner } from "@/api/promo";
import { queryState } from "@/recoil/atoms/search";
import { useUserInfo } from "@dev-togetherprice/api.user";
import { Grid, Hidden, makeStyles, useMediaQuery } from "@material-ui/core";
import theme from '@dev-togetherprice/theme';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

const userInfoConfig = {
  revalidateOnReconnect: false,
  revalidateOnFocus: false,
  revalidateIfStale: false,
  revalidateOnMount: true,
  suspense: true,
};

type BannerData = {
  imageUrl: string;
  link: string;
};

export function AdBanner() {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const timeoutRef = React.useRef<number | null>(null);

  const query = useRecoilValue(queryState);
  const user = useUserInfo(userInfoConfig);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));


  const [searchBannerUrls, setSearchBannerUrls] = React.useState<{
    desktop?: BannerData;
    mobile?: BannerData;
  }>({});

  useEffect(() => {
    const getBannerUrls = async () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(async () => {
        const data = await fetchAdvertisingBanner({
          platform: isMobile ? "mobile" : "desktop",
          type: "IMAGE",
          country: user?.country,
          keywords: query.trim().split(" ").filter(Boolean),
        });

        if (isMobile) {
          setSearchBannerUrls({
            mobile: {
              imageUrl: data[0]?.imageLink,
              link: data[0]?.redirectLink,
            },
          });
        } else {
          setSearchBannerUrls({
            desktop: {
              imageUrl: data[0]?.imageLink,
              link: data[0]?.redirectLink,
            },
          });
        }
      }, 1000);
    };

    getBannerUrls();
  }, [query, user, isMobile, i18n.language]);

  return (
    <Grid container className={classes.container}>
      {searchBannerUrls?.desktop?.imageUrl && (
        <Hidden xsDown>
          <a href={searchBannerUrls.desktop.link} target="_blank">
            <img
              alt={`${query} desktop banner`}
              src={searchBannerUrls.desktop.imageUrl}
              className={classes.banner}
            />
          </a>
        </Hidden>
      )}

      {searchBannerUrls?.mobile?.imageUrl && (
        <Hidden smUp>
          <a href={searchBannerUrls.mobile.link} target="_blank">
            <img
              alt={`${query} mobile banner`}
              src={searchBannerUrls.mobile.imageUrl}
              className={classes.banner}
            />
          </a>
        </Hidden>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  banner: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
      marginTop: theme.spacing(2),
      alignSelf: "center",
  },
  container: {
    justifyContent: "center",
  }
}));
