import { useDiscountedPostPrice } from '@/api/promo';
import { Post } from '@/api/search';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import CompactSharingCard from '../CompactSharingCard';
import SharingCard, { SharingCardProps } from '../SharingCard';
import { LazyWrapper } from '@bit/together-price.core.components.lazy-wrapper';

export type ResponsiveSharingCardProps = {
  post: Post;
  noPromo?: boolean;
  onClickPost?: (post: Post) => void;
  onClickPostAdmin?: (post: Post) => void;
} & Partial<SharingCardProps>;

const ResponsiveSharingCard: FC<ResponsiveSharingCardProps> = ({
  post,
  noPromo,
  onClickPost,
  onClickPostAdmin,
  ...props
}) => {
  const theme = useTheme();
  const isDesktopVariant = useMediaQuery(theme.breakpoints.up('md'));

  const { admin, joiners = [] } = post;
  const postUrl = `/post/${post.sharingPostId}`;
  const profileUrl = `/profile/${admin.userId}`;
  const [discountedPostPrice, discounted] = useDiscountedPostPrice(
    {
      monthlyPrice: post.monthlyPrice,
      feePrice: post.feePrice,
      frequency: post.frequency,
    },
    noPromo
  );

  const postAdmin = {
    fullName: admin.adminFullname,
    profileUrl,
    profilePictureUrl: admin.imagePath,
    ratings: admin.ratings,
    trust: admin.trust,
    verified: admin.verified,
  };
  const postJoiners = joiners.map((joiner) => ({
    fullName: [joiner.name, joiner.surname].join(' '),
    profilePictureUrl: joiner.imagePath,
  }));
  const onClick = (e) => {
    e.preventDefault();
    onClickPost?.(post);
  };
  const onClickAdmin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClickPostAdmin?.(post);
  };

  return isDesktopVariant ? (
    <LazyWrapper
      key={`SharingCard-${post.sharingPostId}-LazyWrapper`}
      options={{ rootMargin: '100px 0px 100px 0px' }}
      width={256}
      height={300}
    >
      <SharingCard
        key={`SharingCard-${post.sharingPostId}`}
        sharingPostId={post.sharingPostId}
        tag={post.tags.slice(-1)[0]}
        title={post.postTitle}
        cover={post.imagePath}
        postUrl={postUrl}
        price={discountedPostPrice}
        discounted={discounted}
        frequency={post.frequency}
        freeAvailability={post.freeAvailability}
        secretStatus={post.secretStatus}
        admin={postAdmin}
        joiners={postJoiners}
        onClick={onClick}
        onClickAdmin={onClickAdmin}
        {...props}
      />
    </LazyWrapper>
  ) : (
    <LazyWrapper
      key={`CompactSharingCard-${post.sharingPostId}-LazyWrapper`}
      options={{ rootMargin: '50px 0px 50px 0px' }}
      width='100%'
      height={180}
    >
      <CompactSharingCard
        key={`CompactSharingCard-${post.sharingPostId}`}
        sharingPostId={post.sharingPostId}
        tag={post.tags.slice(-1)[0]}
        title={post.postTitle}
        postUrl={postUrl}
        price={discountedPostPrice}
        discounted={discounted}
        frequency={post.frequency}
        freeAvailability={post.freeAvailability}
        secretStatus={post.secretStatus}
        admin={postAdmin}
        joiners={postJoiners}
        onClick={onClick}
        onClickAdmin={onClickAdmin}
        {...props}
      />
    </LazyWrapper>
  );
};

export default ResponsiveSharingCard;
