import React from 'react';
import { Trans } from 'react-i18next';
import { ServiceCategory } from '../../api/service';

export type ServiceCategoryDescriptionProps = {
  value: ServiceCategory,
};
const ServiceCategoryDescription: React.FC<ServiceCategoryDescriptionProps> = ({
  value,
}) => {
  /*
   This switch could be replaced with a template string or string concatenation,
   but using the switch we can use the i18n plugin on WebStorm
   and will have code assist when adding a new category.
  */
  switch (value) {
    case 'teamwork':
      return <Trans i18nKey="ServiceCategoryDescription.teamwork">teamwork</Trans>;
    case 'software':
      return <Trans i18nKey="ServiceCategoryDescription.software">software</Trans>;
    case 'streaming':
      return <Trans i18nKey="ServiceCategoryDescription.streaming">streaming</Trans>;
    case 'music':
      return <Trans i18nKey="ServiceCategoryDescription.music">music</Trans>;
    case 'sport':
      return <Trans i18nKey="ServiceCategoryDescription.sport">sport</Trans>;
    case 'productivity':
      return <Trans i18nKey="ServiceCategoryDescription.productivity">productivity</Trans>;
    case 'gaming':
      return <Trans i18nKey="ServiceCategoryDescription.gaming">gaming</Trans>;
    case 'reading':
      return <Trans i18nKey="ServiceCategoryDescription.reading">reading</Trans>;
    case 'security':
      return <Trans i18nKey="ServiceCategoryDescription.security">security</Trans>;
    case 'privacy':
      return <Trans i18nKey="ServiceCategoryDescription.privacy">privacy</Trans>;
    case 'storage':
      return <Trans i18nKey="ServiceCategoryDescription.storage">storage</Trans>;
    case 'education':
      return <Trans i18nKey="ServiceCategoryDescription.education">education</Trans>;
    case 'documentary':
      return <Trans i18nKey="ServiceCategoryDescription.documentary">documentary</Trans>;
    case 'photography':
      return <Trans i18nKey="ServiceCategoryDescription.photography">photography</Trans>;
    case 'bundle':
      return <Trans i18nKey="ServiceCategoryDescription.bundle">bundle</Trans>;
    default:
      return <></>;
  }
};

export default ServiceCategoryDescription;
