import {
  Badge,
  createStyles,
  StyledComponentProps,
  StyledProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { PostSecretStatus } from '../../api/search';
import SecretKey from '../SecretKey';

const SecretKeyBadge = withStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'relative',
        width: 'fit-content',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
      },
      badge: {
        width: 'fit-content',
        height: 'auto',
        transform: 'translateY(-10%)',
        bottom: 0,
        right: theme.spacing(3),
      },
    }),
  { name: 'TpSecretKeyBadge' }
)(Badge);

export type SharingPostTitleClassKey = 'root' | 'title' | 'secretKey';
export type SharingPostTitleProps = {
  title: string;
  secretStatus?: PostSecretStatus;
} & Partial<StyledProps> &
  StyledComponentProps<SharingPostTitleClassKey>;
const SharingPostTitle: React.FC<SharingPostTitleProps> = ({
  title,
  secretStatus,
  className,
  classes = {},
}) => (
  <SecretKeyBadge
    className={className}
    invisible={!secretStatus}
    badgeContent={
      <SecretKey className={classes.secretKey} status={secretStatus} />
    }
  >
    <Typography
      component='div'
      variant='h1'
      align='center'
      aria-label={title}
      className={classes.title}
      style={
        secretStatus
          ? {
              paddingRight: '50px',
            }
          : {}
      }
      noWrap
    >
      {title}
    </Typography>
  </SecretKeyBadge>
);

export default SharingPostTitle;
