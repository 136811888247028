import { useNavigateToUrl } from "@bit/together-price.core.components.app-context";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import en from "./i18n/en.json";
import es from "./i18n/es.json";
import it from "./i18n/it.json";
import pt from "./i18n/pt.json";
import useRegisteredTranslation from "@bit/together-price.core.hooks.use-register-translation";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    justifyContent: "space-between",
    padding: theme.spacing(4),
    margin: "-8px",
    height: "100vh",
    width: "100vw",
  },
  text: {
    fontSize: "24px",
  },
  successMessage: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(8),
  },
}));

const ShortFunnelSuccess: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const navigateToUrl = useNavigateToUrl();
  const params = useParams<{ postId: string }>();
  const { t } = useRegisteredTranslation("ShortFunnelResult", { en, es, it, pt });
  

  return (
    <Grid className={classes.root} container direction="column">
      <Grid item className={classes.successMessage}>
        <Typography
          color="primary"
          variant="subtitle1"
          align="center"
          className={classes.text}
        >
          <Trans t={t} i18nKey='Success.message' />
        </Typography>
        <Success />
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigateToUrl(`/post/${params.postId}`);
        }}
      >
         <Trans t={t} i18nKey='Success.button' />
      </Button>
    </Grid>
  );
};

export default ShortFunnelSuccess;


const Success= (props) => {
  return (
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" {...props}>
      <circle cx="68" cy="68" r="68" fill="#F9FAFB" />
      <circle cx="68" cy="68" r="68" fill="#EFF4FF" />
      <path
        d="M111.954 81.4619C111.299 83.766 110.316 86.3996 109.007 88.3745C105.732 96.2742 96.8922 105.819 84.4505 110.756C76.92 113.719 68.0801 115.036 58.5851 113.061C33.7017 107.795 17.9862 83.4372 22.8974 58.7505C30.4279 22.8727 74.3008 9.70614 100.167 35.3802C112.936 47.8881 116.865 65.6625 111.954 81.4619Z"
        fill="#22C55E"
      />
      <path
        d="M51 67.5L62.4583 80L85.375 55"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};