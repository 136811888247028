import React from 'react';
import {
  Box,
  createStyles,
  Divider,
  Theme,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Money } from '@dev-togetherprice/monetary';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import AdminAvatar from '@dev-togetherprice/components.admin-avatar';
import LazyAvatar from '@dev-togetherprice/components.lazy-avatar';
import { getImageWithFullPath } from '@/helpers/get-image-with-full-path';
import VerticalCentered from '@dev-togetherprice/components.vertical-centered';
import { useNavigateToUrl } from '@dev-togetherprice/components.app-context';
import { SharingCardFrequency, SharingCardProps } from '../SharingCard';
import AdminFullName from '../AdminFullName';
import JoinersAvatarGroup from '../JoinersAvatarGroup';
import SharingPostTitle from '../SharingPostTitle';
import { shouldGoPost } from '../SharingCard/SharingCard';

export const useStyles = makeStyles<Theme, { noOpacity?: boolean }>(
  (theme) =>
    createStyles({
      root: {
        height: '180px',
        paddingTop: '40px',
        backgroundColor: 'transparent',
        position: 'relative',
      },
      zeroFreeAvailability: {
        opacity: ({ noOpacity }) => !noOpacity && 0.7,
      },
      header: {
        height: '85px',
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.grey.A100,
        borderRadius: theme.spacing(2, 2, 0, 0),
      },
      avatarContainer: {
        marginTop: '-30px',
      },
      footer: {
        height: '55px',
        background: theme.palette.primary.light,
        borderRadius: theme.spacing(0, 0, 2, 2),
        lineHeight: '100%',
      },
      secretKey: {
        marginBottom: -theme.spacing(1.5),
      },
      divider: {
        backgroundColor: theme.palette.primary.main,
        height: '15px',
        width: '0.5px',
        marginTop: '0.40em',
      },
      primaryDark: {
        color: theme.palette.primary.dark,
      },
      title: {
        color: theme.palette.primary.dark,
        fontSize: '19px',
      },
      infoTitle: {
        fontSize: '12px',
        lineHeight: '100%',
        verticalAlign: 'middle',
      },
    }),
  { name: 'CompactSharingCard' }
);

export type CompactSharingCardProps = Omit<SharingCardProps, 'cover'>;

const propsAreEqual = (
  prev: CompactSharingCardProps,
  next: CompactSharingCardProps
) => prev?.id === next?.id && prev?.joiners?.length === next?.joiners?.length;
const CompactSharingCard: React.FC<CompactSharingCardProps> = React.memo(
  ({
    title,
    postUrl,
    price,
    discounted = false,
    frequency,
    freeAvailability,
    secretStatus,
    admin,
    sharingPostId,
    tag,
    checkIfPostMustBeHidden,
    joiners = [],
    onClick,
    onClickAdmin,
    noOpacity,
  }) => {
    const classes = useStyles({ noOpacity });
    const navigateToUrl = useNavigateToUrl();

    return (
      <Container
        role='link'
        data-href={postUrl}
        disableGutters
        onClick={async (props) => {
          // because we are showing a warning message if user has no free slot and onClick function triggers this function
          if (freeAvailability === 0) {
            return onClick(props);
          }
          if (
            (checkIfPostMustBeHidden && (await shouldGoPost(sharingPostId))) ||
            !checkIfPostMustBeHidden
          ) {
            return onClick(props);
          }
          if (tag === 'custom') return navigateToUrl(`/search?q=${title}`);
          return navigateToUrl(`/search?tags=${tag}`);
        }}
        className={clsx(classes.root, {
          [classes.zeroFreeAvailability]: !freeAvailability,
        })}
      >
        <Container className={classes.header} disableGutters>
          <Grid container spacing={2}>
            <Grid item>
              <Container disableGutters className={classes.avatarContainer}>
                <AdminAvatar
                  fullName={admin.fullName}
                  profileUrl={admin.profileUrl}
                  profilePictureUrl={getImageWithFullPath(
                    admin.profilePictureUrl
                  )}
                  verified={admin.verified}
                  trust={admin.trust}
                  onClick={onClickAdmin}
                />
              </Container>
            </Grid>
            <Grid
              item
              xs
              style={{
                width: `calc( 100% - ${(1 + joiners.length) * 45}px - 90px )`,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SharingPostTitle
                    title={title}
                    secretStatus={secretStatus}
                    classes={{
                      title: classes.title,
                      secretKey: classes.secretKey,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AdminFullName
                    fullName={admin.fullName}
                    ratings={admin.ratings}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.footer} disableGutters>
          <VerticalCentered>
            <Grid item xs={12} container justify='space-between'>
              <Grid item xs>
                <Typography
                  component='div'
                  variant='h1'
                  align='center'
                  color={discounted ? 'secondary' : 'primary'}
                  className={clsx({
                    [classes.primaryDark]: !discounted,
                  })}
                >
                  {Money.toString(price)}
                  <Typography
                    variant='overline'
                    align='center'
                    color='primary'
                    className={classes.infoTitle}
                  >
                    {' '}
                    /{' '}
                    <SharingCardFrequency
                      variant={frequency}
                      discounted={discounted}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation='vertical' className={classes.divider} />
              </Grid>
              <Grid item xs>
                <Typography
                  component='div'
                  variant='h1'
                  align='center'
                  color={!freeAvailability ? 'secondary' : 'primary'}
                  className={clsx({
                    [classes.primaryDark]: !!freeAvailability,
                  })}
                >
                  {freeAvailability}
                  <Typography
                    variant='overline'
                    align='center'
                    color='primary'
                    className={classes.infoTitle}
                  >
                    {' '}
                    <Trans i18nKey='SharingCard.free-availability'>
                      POSTI LIBERI
                    </Trans>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </VerticalCentered>
          <Box
            position='absolute'
            bottom='40px'
            left='50%'
            style={{
              transform: 'translateX(-50%)',
            }}
          >
            <JoinersAvatarGroup max={2}>
              {joiners.map((joiner) => (
                <LazyAvatar
                  key={joiner.fullName}
                  alt={joiner.fullName}
                  src={getImageWithFullPath(joiner.profilePictureUrl)}
                />
              ))}
            </JoinersAvatarGroup>
          </Box>
        </Container>
      </Container>
    );
  },
  propsAreEqual
);

export default CompactSharingCard;
